import React from 'react'
import Card from 'src/components/Card'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'

export default class Component extends React.Component {
  render () {
    return (
      <Card
        title={'StarkWare: Eth Address'}
        subheader={'starkProvider.getEthAddress()'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextValueFormGroup
            label='Eth address'
            value={this.props.ethAddress}
          />
          <ButtonFormGroup>
            Get eth address
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit()
  }
}
