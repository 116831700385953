import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      contract: localStorage.getItem('starkSetContract::contract') || '0x5FedCE831BD3Bdb71F938EC26f984c84f40dB477'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Set Contract'}
        subheader={'starkwareProvider.setContractAddress(address)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Contract'
            placeholder='Enter contract address'
            value={this.state.contract}
            onChange={event => {
              const contract = event.target.value
              localStorage.setItem('starkSetContract::contract', contract)
              this.setState({ contract })
            }}
          />
          <ButtonFormGroup>
            Set contract
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
