import React from 'react'
import Card from 'src/components/Card'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'

export default class Component extends React.Component {
  render () {
    return (
      <Card
        title={'StarkWare: Get Contract'}
        subheader={'starkProvider.getContractAddress()'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextValueFormGroup
            label='Contract'
            value={this.props.contract}
          />
          <ButtonFormGroup>
            Get contract
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit()
  }
}
