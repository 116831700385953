import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      quantum: localStorage.getItem('starkWithdrawEth::quantum') || '10',
      recipient: localStorage.getItem('starkWithdrawEth::recipient') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Withdraw ETH'}
        subheader={'starkProvider.withdrawEth(quantum, recipient)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Quantum'
            placeholder='Enter quantum'
            value={this.state.quantum}
            onChange={event => {
              const quantum = event.target.value
              this.setState({ quantum })
              localStorage.setItem('starkWithdrawEth::quantum', quantum)
            }}
          />
          <TextFieldFormGroup
            label='Recipient'
            placeholder='Recipient'
            value={this.state.recipient}
            onChange={event => {
              const recipient = event.target.value
              this.setState({ recipient })
              localStorage.setItem('starkWithdrawEth::recipient', recipient)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Withdraw
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
