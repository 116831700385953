import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      nonce: localStorage.getItem('starkSignNonce::nonce') || `${Date.now() / 1e3}`
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Sign Nonce'}
        subheader={'starkProvider.signNonce(nonce)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Nonce'
            placeholder='Nonce'
            value={this.state.nonce}
            onChange={event => {
              const nonce = event.target.value
              localStorage.setItem('starkNonce::nonce', nonce)
              this.setState({ nonce })
            }}
          />
          <TextValueFormGroup
            label='Signature'
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Sign nonce
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
