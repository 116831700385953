import React from 'react'
import styled from 'styled-components'

const UI = {
  Footer: styled.footer`
    display: block;
    color: #717e82;
    display: block;
    padding: 2em 1em;

    a {
      color: rgba(0, 0, 0, 0.45);
      background: 0 0;
      border-radius: 4px;
      color: inherit;
      font-weight: 600;
      line-height: 1.6;
      padding: 0.5em;
      text-decoration: none;
      -webkit-transition: background-color,color .3s ease;
      transition: background-color,color .3s ease;
    }

    a:hover {
      color: rgba(0, 0, 0, 0.6);
    }

    small {
      font-size: .875em;
      font-weight: 400;
      display: flex;
      justify-content: center;

      @media (max-width: 450px) {
        justify-content: flex-start;
        margin-left: 8px;
      }
    }
  `,
  Container: styled.div`
    max-width: 600px;
    margin: 0 auto;
  `,
  Links: styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 450px) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 1em;
    }
  `,
  SmallLinks: styled.div`
    display: flex;
    justify-content: center;
    a {
      font-size: .8em;
      font-weight: normal;
    }
    @media (max-width: 450px) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 1em;
    }
  `,
  Circle: styled.span`
    display: inline-block;
    margin-right: 0.5em;
    vertical-align: middle;
    color: #59ff59;
    font-size: 0.1em;
}
  `
}

export default class Component extends React.Component {
  render () {
    return (
      <UI.Footer>
        <UI.Container>
          <small>© {new Date().getFullYear()} Authereum</small>
        </UI.Container>
      </UI.Footer>
    )
  }
}
