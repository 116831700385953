import React from 'react'
import styled from 'styled-components'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

const UI = {
  ImageContainer: styled.div`
    text-align: center;
  `
}

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      address: localStorage.getItem('addressQrCode::address') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b'
    }
  }

  render () {
    const { qrCodeDataUri } = this.props

    return (
      <Card
        title={'Authereum: Address QrCode'}
        subheader={'authereum.getAddressQrCodeDataUri(address?)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Account'
            placeholder='Enter account address'
            value={this.state.address}
            onChange={event => {
              const address = event.target.value
              this.setState({ address })
              localStorage.setItem('addressQrCode::address', address)
            }}
          />
          {qrCodeDataUri
            ? <UI.ImageContainer>
              <img src={qrCodeDataUri} alt='QrCode' />
            </UI.ImageContainer>
            : null}
          <ButtonFormGroup>
            Get QR code
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
