import React from 'react'
import Card from 'src/components/Card'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'

export default class Component extends React.Component {
  render () {
    const { connected } = this.props

    return (
      <Card
        title={'Connected'}
        subheader={'web3.currentProvider.isConnected()'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextValueFormGroup
            label='Connected'
            value={`${connected !== undefined ? connected : ''}`}
          />
          <ButtonFormGroup>
            Check
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
