import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('zkSyncTransfer::token') || '',
      to: localStorage.getItem('zkSyncTransfer::to') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      amount: localStorage.getItem('zkSyncTransfer::amount') || '0.1'
    }
  }

  render () {
    return (
      <Card
        title={'ZkSync: Transfer'}
        subheader={'zkSyncProvider.transfer({to, amount, token})'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='To'
            placeholder='Enter to address'
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('zkSyncTransfer::to', to)
              this.setState({ to })
            }}
          />
          <TextFieldFormGroup
            label='Amount'
            placeholder='Enter amount'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              localStorage.setItem('zkSyncTransfer::amount', amount)
              this.setState({ amount })
            }}
          />
          <TextFieldFormGroup
            label='Token (leave blank for ETH)'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('zkSyncTransfer::token', token)
              this.setState({ token })
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Transfer
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
