import React from 'react'
import styled from 'styled-components'
import Card from 'src/components/Card'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'

const UI = {
  Circle: styled.div`
    display: inline-block;
    margin-right: 0.5em;
    vertical-align: middle;
    color: ${props => props.enabled ? '#59ff59' : '#e6575e'};
    font-size: 0.8em;
  `,
  ValueContainer: styled.div`
    display: flex;
    align-items: center;
  `
}

export default class Component extends React.Component {
  render () {
    const { enabled } = this.props
    const value = (
      <UI.ValueContainer>
        {enabled !== undefined
          ? <UI.Circle enabled={enabled}>⬤</UI.Circle>
          : null }
        {`${enabled !== undefined ? enabled : '-'}`.toUpperCase()}
      </UI.ValueContainer>
    )

    return (
      <Card
        title={'Enable'}
        subheader={'web3.currentProvider.enable()'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextValueFormGroup
            label='Enabled'
            value={value}
          />
          <ButtonFormGroup
            disabled={enabled}
          >
            Enable
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
