import React from 'react'
import { Authereum, version as latestVersion } from 'authereum'
import Web3 from 'web3'
import pify from 'pify'
import etherConverter from 'ether-converter'
import fromWei from '@authereum/utils/core/fromWei'
import toWei from '@authereum/utils/core/toWei'
import sha3 from '@authereum/utils/core/sha3'
import toHex from '@authereum/utils/core/toHex'
import hashPersonalMessage from '@authereum/utils/core/hashPersonalMessage'
import hashTypedDataV1 from '@authereum/utils/core/hashTypedDataV1'
import hashTypedDataV3 from '@authereum/utils/core/hashTypedDataV3'
import getQueryParam from '@authereum/utils/core/getQueryParam'
import { theme } from '@authereum/mui-theme'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import SiteHeader from 'src/components/SiteHeader'
import SiteFooter from 'src/components/SiteFooter'
import SiteLoader from 'src/components/SiteLoader'

import ProviderEnableWidget from 'src/components/widgets/ProviderEnableWidget'
import ProviderDisableWidget from 'src/components/widgets/ProviderDisableWidget'

import IsConnectedWidget from 'src/components/widgets/IsConnectedWidget'
import GetBalanceWidget from 'src/components/widgets/GetBalanceWidget'
import GetCoinbaseWidget from 'src/components/widgets/GetCoinbaseWidget'
import GetAccountsWidget from 'src/components/widgets/GetAccountsWidget'
import GetTransactionReceiptWidget from 'src/components/widgets/GetTransactionReceiptWidget'

import SignWidget from 'src/components/widgets/SignWidget'
import PersonalSignWidget from 'src/components/widgets/PersonalSignWidget'
import SignTypedDataWidget from 'src/components/widgets/SignTypedDataWidget'
import SignTypedDataV3Widget from 'src/components/widgets/SignTypedDataV3Widget'

import SignTransactionWidget from 'src/components/widgets/SignTransactionWidget'
import SendTransactionWidget from 'src/components/widgets/SendTransactionWidget'
import SendTransactionBatchWidget from 'src/components/widgets/SendTransactionBatchWidget'

import TokenBalanceOfWidget from 'src/components/widgets/TokenBalanceOfWidget'
import TokenAllowanceWidget from 'src/components/widgets/TokenAllowanceWidget'
import TokenApproveWidget from 'src/components/widgets/TokenApproveWidget'
import TokenTransferWidget from 'src/components/widgets/TokenTransferWidget'
import TokenTransferFromWidget from 'src/components/widgets/TokenTransferFromWidget'
import TokenMintWidget from 'src/components/widgets/TokenMintWidget'
import TokenBurnWidget from 'src/components/widgets/TokenBurnWidget'

import NftOwnerOfWidget from 'src/components/widgets/NftOwnerOfWidget'
import NftGetApprovedWidget from 'src/components/widgets/NftGetApprovedWidget'
import NftApproveWidget from 'src/components/widgets/NftApproveWidget'
import NftApproveAllWidget from 'src/components/widgets/NftApproveAllWidget'
import NftTransferFromWidget from 'src/components/widgets/NftTransferFromWidget'
import NftSafeTransferFromWidget from 'src/components/widgets/NftSafeTransferFromWidget'

import HashPersonalMessageWidget from 'src/components/widgets/HashPersonalMessageWidget'
import ValidateSignatureWidget from 'src/components/widgets/ValidateSignatureWidget'
import EstimateGasWidget from 'src/components/widgets/EstimateGasWidget'
import EstimateGasBatchWidget from 'src/components/widgets/EstimateGasBatchWidget'
import EtherConverterWidget from 'src/components/widgets/EtherConverterWidget'
import SignMessageWithSigningKeyWidget from 'src/components/widgets/SignMessageWithSigningKeyWidget'
import GetEncryptionPublicKeyWidget from 'src/components/widgets/GetEncryptionPublicKeyWidget'
import EncryptMessageWidget from 'src/components/widgets/EncryptMessageWidget'
import DecryptMessageWidget from 'src/components/widgets/DecryptMessageWidget'
import ContractDeployedWidget from 'src/components/widgets/ContractDeployedWidget'
import AddressQrCodeWidget from 'src/components/widgets/AddressQrCodeWidget'
import WidgetToggleWidget from 'src/components/widgets/WidgetToggleWidget'
import VersionWidget from 'src/components/widgets/VersionWidget'
import AddFundsWidget from 'src/components/widgets/AddFundsWidget'
// import TboxChatboxWidget from 'src/components/widgets/TboxChatboxWidget'

import StarkAccountWidget from 'src/components/widgets/StarkAccountWidget'
import StarkPublicKeyWidget from 'src/components/widgets/StarkPublicKeyWidget'
import StarkEthAddressWidget from 'src/components/widgets/StarkEthAddressWidget'
import StarkSignNonceWidget from 'src/components/widgets/StarkSignNonceWidget'
import StarkSetContractWidget from 'src/components/widgets/StarkSetContractWidget'
import StarkGetContractWidget from 'src/components/widgets/StarkGetContractWidget'
import StarkRegisterUserWidget from 'src/components/widgets/StarkRegisterUserWidget'
import StarkDepositEthWidget from 'src/components/widgets/StarkDepositEthWidget'
import StarkDepositErc20Widget from 'src/components/widgets/StarkDepositErc20Widget'
import StarkDepositErc721Widget from 'src/components/widgets/StarkDepositErc721Widget'
import StarkDepositCancelWidget from 'src/components/widgets/StarkDepositCancelWidget'
import StarkDepositReclaimWidget from 'src/components/widgets/StarkDepositReclaimWidget'
import StarkWithdrawEthWidget from 'src/components/widgets/StarkWithdrawEthWidget'
import StarkWithdrawErc20Widget from 'src/components/widgets/StarkWithdrawErc20Widget'
import StarkWithdrawErc721Widget from 'src/components/widgets/StarkWithdrawErc721Widget'
import StarkFullWithdrawalRequestWidget from 'src/components/widgets/StarkFullWithdrawalRequestWidget'
import StarkWithdrawAndMintWidget from 'src/components/widgets/StarkWithdrawAndMintWidget'
import StarkFreezeRequestWidget from 'src/components/widgets/StarkFreezeRequestWidget'
import StarkEscapeWidget from 'src/components/widgets/StarkEscapeWidget'
import StarkGetAssetTypeWidget from 'src/components/widgets/StarkGetAssetTypeWidget'
import StarkTransferWidget from 'src/components/widgets/StarkTransferWidget'
import StarkTransferEthWidget from 'src/components/widgets/StarkTransferEthWidget'
import StarkTransferErc20Widget from 'src/components/widgets/StarkTransferErc20Widget'
import StarkTransferErc721Widget from 'src/components/widgets/StarkTransferErc721Widget'
import StarkCreateOrderWidget from 'src/components/widgets/StarkCreateOrderWidget'
import ZkSyncDepositWidget from 'src/components/widgets/ZkSyncDepositWidget'
import ZkSyncWithdrawWidget from 'src/components/widgets/ZkSyncWithdrawWidget'
import ZkSyncTransferWidget from 'src/components/widgets/ZkSyncTransferWidget'

import erc20Abi from 'src/abi/ERC20'
import erc721Abi from 'src/abi/ERC721'
import erc1271Abi from 'src/abi/ERC1271'
import {
  webUri,
  apiUri,
  rpcUri,
  xsUri,
  networkName,
  authereumVersions
} from 'src/config'
import logger from 'src/logger'

const muiTheme = createMuiTheme(theme)

class App extends React.Component {
  constructor (props) {
    super(props)

    let sdkVersion = getQueryParam('version')
    if (!sdkVersion || !authereumVersions.includes(sdkVersion)) {
      sdkVersion = latestVersion
    }

    // manual init is to test sdk iframe preloading
    let manualInit = !!getQueryParam('manual_init')
    let autoLogin = !!getQueryParam('auto_login')

    this.state = {
      manualInit,
      autoLogin,
      sdkVersion,
      loading: true,
      accountAddress: null,

      headerProps: {
        networkName,
        sdkVersion
      },

      providerEnableOutput: {},
      providerDisableOutput: {},

      isConnectedOutput: {},
      getBalanceOutput: {},
      getCoinbaseOutput: {},
      getAccountsOutput: {},
      getTransactionReceiptOutput: {},

      signOutput: {},
      personalSignOutput: {},
      signTypedDataOutput: {},
      signTypedDataV3Output: {},
      signTransactionOutput: {},
      sendTransactionOutput: {},
      sendTransactionBatchOutput: {},

      tokenBalanceOfOutput: {},
      tokenAllowanceOutput: {},
      tokenApproveOutput: {},
      tokenTransferOutput: {},
      tokenTransferFromOutput: {},
      tokenMintOutput: {},
      tokenBurnOutput: {},

      nftOwnerOfOutput: {},
      nftGetApprovedOutput: {},
      nftApproveOutput: {},
      nftApproveAllOutput: {},
      nftTransferFromOutput: {},
      nftSafeTransferFromOutput: {},

      hashPersonalMessageOutput: {},
      validateSignatureOutput: {},
      estimateGasOutput: {},
      estimateGasBatchOutput: {},
      EtherConverterWidget: {},
      signMessageWithAdminKeyOutput: {},
      signMessageWithSigningKeyOutput: {},
      getEncryptionPublicKeyOutput: {},
      encryptMessageOutput: {},
      decryptMessageOutput: {},
      contractDeployedOutput: {},
      addressQrCodeOutput: {},
      widgetToggleOutput: {},
      versionOutput: {},
      addFundsOutput: {},

      tboxChatboxOuput: {},

      starkAccountOutput: {},
      starkPublicKeyOutput: {},
      starkEthAddressOutput: {},
      starkSignNonceOutput: {},
      starkSetContractOutput: {},
      starkGetContractOutput: {},
      starkRegisterUserOutput: {},
      starkDepositEthOutput: {},
      starkDepositErc20Output: {},
      starkDepositErc721Output: {},
      starkDepositCancelOutput: {},
      starkDepositReclaimOutput: {},
      starkWithdrawEthOutput: {},
      starkWithdrawErc20Output: {},
      starkWithdrawErc721Output: {},
      starkFullWithdrawalRequestOutput: {},
      starkWithdrawAndMint: {},
      starkFreezeRequestOutput: {},
      starkEscapeOutput: {},
      starkGetAssetTypeOutput: {},
      starkTransferOutput: {},
      starkTransferEthOutput: {},
      starkTransferErc20Output: {},
      starkTransferErc721Output: {},
      starkCreateOrderOutput: {},

      zkSyncDepositOutput: {},
      zkSyncWithdrawOutput: {},
      zkSyncTransferOutput: {}
    }

    if (!manualInit) {
      this.init()
    }

    // for debugging re-initializations
    window.init = this.init.bind(this)
  }

  async init () {
    try {
      const { sdkVersion } = this.state
      logger.debug('sdk version:', sdkVersion)

      if (sdkVersion !== latestVersion) {
        const cdnUrl = `https://cdn.jsdelivr.net/npm/authereum@${sdkVersion}/authereum.js`
        await this.injectScript(cdnUrl)
      } else {
        window.Authereum = Authereum
      }

      const authereum = new window.Authereum({
        networkName,
        webUri,
        apiUri,
        rpcUri,
        xsUri,
        disableNotifications: !!getQueryParam('disable_notifications'),
        forceRedirect: !!getQueryParam('force_redirect'),
        disableGoogleAnalytics: !!getQueryParam('disable_google_analytics')
        // apiKey: 'YgRvE8g-dz6coT72DmZKtou7eKbx-5lug1_BYVBTRqE' // ropsten
      })

      const provider = authereum.getProvider()
      this.web3 = new Web3(provider)

      window.authereum = authereum
      window.ethereum = provider
      window.web3 = this.web3

      authereum
        .on('ready', () => {
          logger.debug('ready')
          this.refreshAccount()
        })
        .on('iframeReady', () => {
          logger.debug('iframeReady')
        })
        .on('openPopup', () => {
          logger.debug('openPopup')
        })
        .on('closePopup', () => {
          logger.debug('closePopup')
          this.refreshAccount()
        })
        .on('popupBlocked', () => {
          logger.debug('popupBlocked')
        })
        .on('login', () => {
          logger.debug('login')
          this.refreshAccount()
        })
        .on('logout', () => {
          logger.debug('logout')
          this.refreshAccount()
        })
        .on('dappKeyExpired', () => {
          logger.debug('dappKeyExpired')
        })
        .on('error', (err) => {
          logger.debug('error', err)
        })
        // .showWidget()

      if (this.state.manualInit || this.state.autoLogin) {
        await authereum.login()
      }
    } catch (err) {
      logger.error(err)
    }
  }

  injectScript = async (sourceUrl, id = '') => {
    return new Promise((resolve, reject) => {
      if (!sourceUrl) {
        throw new Error('sourceUrl is required')
      }

      if (id && document.getElementById(id)) {
        resolve()
        return
      }

      const script = document.createElement('script')
      script.id = id
      script.type = 'text/javascript'
      script.async = true
      script.onload = () => {
        resolve()
      }

      script.onerror = (err) => {
        reject(err)
      }

      script.src = sourceUrl
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }

  render () {
    return (
      <MuiThemeProvider theme={muiTheme}>
        {this.renderLoader()}
        {this.renderHeader()}
        {this.state.manualInit && <button onClick={() => this.init()}>manual init</button>}
        {this.renderWidgets()}
        {this.renderFooter()}
      </MuiThemeProvider>
    )
  }

  renderHeader () {
    return (
      <SiteHeader {...this.state.headerProps} />
    )
  }

  renderFooter () {
    return (
      <SiteFooter />
    )
  }

  renderLoader () {
    if (!this.state.loading) return null

    return (
      <SiteLoader />
    )
  }

  renderWidgets () {
    return (
      <Grid item style={{
        padding: '1em'
      }}>
        <Grid container>
          <ProviderEnableWidget onSubmit={this.providerEnable} {...this.state.providerEnableOutput} />
          <ProviderDisableWidget onSubmit={this.providerDisable} {...this.state.providerDisableOutput} />

          <IsConnectedWidget onSubmit={this.isConnected} {...this.state.isConnectedOutput} />
          <GetBalanceWidget onSubmit={this.getBalance} {...this.state.getBalanceOutput} />
          <GetCoinbaseWidget onSubmit={this.getCoinbase} {...this.state.getCoinbaseOutput} />
          <GetAccountsWidget onSubmit={this.getAccounts} {...this.state.getAccountsOutput} />
          <GetTransactionReceiptWidget onSubmit={this.getTransactionReceipt} {...this.state.getTransactionReceiptOutput} />

          <SignWidget onSubmit={this.sign} {...this.state.signOutput} />
          <PersonalSignWidget onSubmit={this.personalSign} {...this.state.personalSignOutput} />
          <SignTypedDataWidget onSubmit={this.signTypedData} {...this.state.signTypedDataOutput} />
          <SignTypedDataV3Widget onSubmit={this.signTypedDataV3} {...this.state.signTypedDataV3Output} />

          <SignTransactionWidget onSubmit={this.signTransaction} {...this.state.signTransactionOutput} />
          <SendTransactionWidget onSubmit={this.sendTransaction} {...this.state.sendTransactionOutput} />
          <SendTransactionBatchWidget onSubmit={this.sendTransactionBatch} {...this.state.sendTransactionBatchOutput} />

          <TokenBalanceOfWidget onSubmit={this.tokenBalanceOf} {...this.state.tokenBalanceOfOutput} />
          <TokenAllowanceWidget onSubmit={this.tokenAllowance} {...this.state.tokenAllowanceOutput} />
          <TokenApproveWidget onSubmit={this.tokenApprove} {...this.state.tokenApproveOutput} />
          <TokenTransferWidget onSubmit={this.tokenTransfer} {...this.state.tokenTransferOutput} />
          <TokenTransferFromWidget onSubmit={this.tokenTransferFrom} {...this.state.tokenTransferFromOutput} />
          <TokenMintWidget onSubmit={this.tokenMint} {...this.state.tokenMintOutput} />
          <TokenBurnWidget onSubmit={this.tokenBurn} {...this.state.tokenBurnOutput} />

          <NftOwnerOfWidget onSubmit={this.nftOwnerOf} {...this.state.nftOwnerOfOutput} />
          <NftGetApprovedWidget onSubmit={this.nftGetApproved} {...this.state.nftGetApprovedOutput} />
          <NftApproveWidget onSubmit={this.nftApprove} {...this.state.nftApproveOutput} />
          <NftApproveAllWidget onSubmit={this.nftApproveAll} {...this.state.nftApproveAllOutput} />
          <NftTransferFromWidget onSubmit={this.nftTransferFrom} {...this.state.nftTransferFromOutput} />
          <NftSafeTransferFromWidget onSubmit={this.nftSafeTransferFrom} {...this.state.nftSafeTransferFromOutput} />

          <HashPersonalMessageWidget onSubmit={this.hashPersonalMessage} {...this.state.hashPersonalMessageOutput} />
          <ValidateSignatureWidget onSubmit={this.validateSignature} {...this.state.validateSignatureOutput} />
          <EstimateGasWidget onSubmit={this.estimateGas} {...this.state.estimateGasOutput} />
          <EstimateGasBatchWidget onSubmit={this.estimateGasBatch} {...this.state.estimateGasBatchOutput} />
          <EtherConverterWidget onSubmit={this.etherConverter} {...this.state.etherConverterOutput} />
          <SignMessageWithSigningKeyWidget onSubmit={this.signMessageWithSigningKey} {...this.state.signMessageWithSigningKeyOutput} />
          <GetEncryptionPublicKeyWidget onSubmit={this.getEncryptionPublicKey} {...this.state.getEncryptionPublicKeyOutput} />
          <EncryptMessageWidget onSubmit={this.encryptMessage} {...this.state.encryptMessageOutput} />
          <DecryptMessageWidget onSubmit={this.decryptMessage} {...this.state.decryptMessageOutput} />
          <ContractDeployedWidget onSubmit={this.isContractDeployed} {...this.state.contractDeployedOutput} />
          <AddressQrCodeWidget onSubmit={this.addressQrCode} {...this.state.addressQrCodeOutput} />
          <WidgetToggleWidget onSubmit={this.widgetToggle} {...this.state.widgetToggleOutput} />

          <VersionWidget onSubmit={this.version} {...this.state.versionOutput} />

          <AddFundsWidget onSubmit={this.addFunds} {...this.state.addFundsOutput} />

          {/* <TboxChatboxWidget {...this.state.tboxChatboxOutput} /> */}

          <StarkAccountWidget onSubmit={this.starkAccount} {...this.state.starkAccountOutput} />
          <StarkPublicKeyWidget onSubmit={this.starkPublicKey} {...this.state.starkPublicKeyOutput} />
          <StarkEthAddressWidget onSubmit={this.starkEthAddress} {...this.state.starkEthAddressOutput} />
          <StarkSignNonceWidget onSubmit={this.starkSignNonce} {...this.state.starkSignNonceOutput} />
          <StarkSetContractWidget onSubmit={this.starkSetContract} {...this.state.starkSetContractOutput} />
          <StarkGetContractWidget onSubmit={this.starkGetContract} {...this.state.starkGetContractOutput} />
          <StarkRegisterUserWidget onSubmit={this.starkRegisterUser} {...this.state.starkRegisterUserOutput} />
          <StarkDepositEthWidget onSubmit={this.starkDepositEth} {...this.state.starkDepositEthOutput} />
          <StarkDepositErc20Widget onSubmit={this.starkDepositErc20} {...this.state.starkDepositErc20Output} />
          <StarkDepositErc721Widget onSubmit={this.starkDepositErc721} {...this.state.starkDepositErc721Output} />
          <StarkDepositCancelWidget onSubmit={this.starkDepositCancel} {...this.state.starkDepositCancelOutput} />
          <StarkDepositReclaimWidget onSubmit={this.starkDepositReclaim} {...this.state.starkDepositReclaimOutput} />
          <StarkWithdrawEthWidget onSubmit={this.starkWithdrawEth} {...this.state.starkWithdrawEthOutput} />
          <StarkWithdrawErc20Widget onSubmit={this.starkWithdrawErc20} {...this.state.starkWithdrawErc20Output} />
          <StarkWithdrawErc721Widget onSubmit={this.starkWithdrawErc721} {...this.state.starkWithdrawErc721Output} />
          <StarkFullWithdrawalRequestWidget onSubmit={this.starkFullWithdrawalRequest} {...this.state.starkFullWithdrawalRequestOutput} />
          <StarkWithdrawAndMintWidget onSubmit={this.starkWithdrawAndMint} {...this.state.starkWithdrawAndMintOutput} />
          <StarkFreezeRequestWidget onSubmit={this.starkFreezeRequest} {...this.state.starkFreezeRequestOutput} />
          <StarkEscapeWidget onSubmit={this.starkEscape} {...this.state.starkEscapeOutput} />
          <StarkGetAssetTypeWidget onSubmit={this.starkGetAssetType} {...this.state.starkGetAssetTypeOutput} />
          <StarkTransferWidget onSubmit={this.starkTransfer} {...this.state.starkTransferOutput} />
          <StarkTransferEthWidget onSubmit={this.starkTransferEth} {...this.state.starkTransferEthOutput} />
          <StarkTransferErc20Widget onSubmit={this.starkTransferErc20} {...this.state.starkTransferErc20Output} />
          <StarkTransferErc721Widget onSubmit={this.starkTransferErc721} {...this.state.starkTransferErc721Output} />
          <StarkCreateOrderWidget onSubmit={this.starkCreateOrder} {...this.state.starkCreateOrderOutput} />

          <ZkSyncDepositWidget onSubmit={this.zkSyncDeposit} {...this.state.zkSyncDepositOutput} />
          <ZkSyncWithdrawWidget onSubmit={this.zkSyncWithdraw} {...this.state.zkSyncWithdrawOutput} />
          <ZkSyncTransferWidget onSubmit={this.zkSyncTransfer} {...this.state.zkSyncTransferOutput} />
        </Grid>
      </Grid>
    )
  }

  refreshAccount = async () => {
    try {
      if (!this.web3) return

      const accounts = await this.web3.eth.getAccounts()
      const accountAddress = accounts.length > 0 ? accounts[0] : null
      const provider = this.web3.currentProvider
      const enabled = provider.isConnected()

      this.setState({
        accountAddress,
        providerEnableOutput: {
          enabled
        },
        providerDisableOutput: {
          disabled: !enabled
        },
        widgetToggleOutput: {
          enabled: this.web3.currentProvider.widgetEnabled()
        },
        loading: false,
        tboxChatbox: {
          provider,
          accountAddress
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  providerEnable = async () => {
    try {
      await this.web3.currentProvider.enable()
      const enabled = this.web3.currentProvider.isConnected()
      this.setState({
        providerEnableOutput: {
          enabled
        },
        providerDisableOutput: {
          enabled: !enabled
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  providerDisable = async () => {
    try {
      await this.web3.currentProvider.disable()
      const disabled = !this.web3.currentProvider.isConnected()
      this.setState({
        providerDisableOutput: {
          disabled
        },
        providerEnableOutput: {
          disabled: !disabled
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  isConnected = async (input) => {
    try {
      const connected = await this.web3.currentProvider.isConnected()

      this.setState({
        isConnectedOutput: {
          connected
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getBalance = async (input) => {
    try {
      const { account } = input
      const balance = await this.web3.eth.getBalance(account)

      this.setState({
        getBalanceOutput: {
          value: fromWei(balance, 'ether')
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getCoinbase = async () => {
    try {
      const coinbase = await this.web3.eth.getCoinbase()

      this.setState({
        getCoinbaseOutput: {
          coinbase
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getAccounts = async () => {
    try {
      const accounts = await this.web3.eth.getAccounts()

      this.setState({
        getAccountsOutput: {
          accounts
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getTransactionReceipt = async (input) => {
    try {
      const { transactionHash } = input
      const receipt = await this.web3.currentProvider.getTransactionReceipt(transactionHash)

      this.setState({
        getTransactionReceiptOutput: {
          receipt
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  sign = async (input) => {
    try {
      this.requireLogin()

      const { message } = input
      const { accountAddress } = this.state
      const signature = await this.web3.eth.sign(message, accountAddress)

      this.setState({
        signOutput: {
          signature
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  personalSign = async (input) => {
    try {
      this.requireLogin()

      const { message } = input
      const { accountAddress } = this.state

      const signature = await this.web3.eth.personal.sign(message, accountAddress)
      this.setState({
        personalSignOutput: {
          signature
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signTypedData = async (input) => {
    try {
      this.requireLogin()

      const { data } = input
      const payload = JSON.parse(data)
      const hash = hashTypedDataV1(payload)
      const from = this.state.accountAddress

      const params = [payload, from]
      const method = 'eth_signTypedData'

      await this.web3.currentProvider.sendAsync({
        id: 1,
        method,
        params,
        from
      }, (err, res) => {
        if (err) {
          throw err
        }

        const signature = res.result

        this.setState({
          signTypedDataOutput: {
            signature,
            hash
          }
        })
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signTypedDataV3 = async (input) => {
    try {
      this.requireLogin()

      const { data } = input
      const payload = JSON.parse(data)
      const hash = hashTypedDataV3(payload)
      const from = this.state.accountAddress

      const params = [payload, from]
      const method = 'eth_signTypedData'

      this.web3.currentProvider.sendAsync({
        id: 1,
        method,
        params,
        from
      }, (err, res) => {
        if (err) {
          throw err
        }

        const signature = res.result

        this.setState({
          signTypedDataV3Output: {
            signature,
            hash
          }
        })
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signTransaction = async (input) => {
    try {
      this.requireLogin()

      const { to, value, data, gasPrice, gasLimit } = input

      const tx = {
        from: this.state.accountAddress,
        to
      }

      if (value !== '') {
        tx.value = toWei(value, 'ether')
      }

      if (data !== '') {
        tx.data = data
      }

      if (gasPrice !== '') {
        tx.gasPrice = toWei(gasPrice, 9)
      }

      if (gasLimit !== '') {
        tx.gas = gasLimit
      }

      const { raw } = await this.web3.eth.signTransaction(tx)
      this.setState({
        signTransactionOutput: {
          signedTransaction: raw,
          transactionHash: sha3(raw)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  sendTransaction = async (input) => {
    try {
      this.requireLogin()

      const { to, value, data, gasPrice, gasLimit } = input

      const tx = {
        from: this.state.accountAddress,
        to
      }

      if (value !== '') {
        tx.value = toWei(value, 'ether')
      }

      if (data !== '') {
        tx.data = data
      }

      if (gasPrice !== '') {
        tx.gasPrice = toWei(gasPrice, 9)
      }

      if (gasLimit !== '') {
        tx.gas = gasLimit
      }

      const transactionHash = await pify(this.web3.eth.sendTransaction)(tx)
      this.setState({
        sendTransactionOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  sendTransactionBatch = async (input) => {
    try {
      this.requireLogin()

      const { transactions } = input

      for (let i = 0; i < transactions.length; i++) {
        const tx = transactions[i]
        if (tx.value !== '') {
          tx.value = toWei(tx.value, 'ether')
        }

        if (tx.gasPrice !== '') {
          tx.gasPrice = toWei(tx.gasPrice, 9)
        }
      }

      const { transactionHash } = await this.web3.currentProvider.sendTransactionBatch(transactions)

      this.setState({
        sendTransactionBatchOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenBalanceOf = async (input) => {
    try {
      const { token, account } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const value = await instance.methods.balanceOf(account).call()
      const decimals = await instance.methods.decimals().call()
      const balance = fromWei(value, +decimals)

      this.setState({
        tokenBalanceOfOutput: {
          balance
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenAllowance = async (input) => {
    try {
      const { token, owner, spender } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()

      const value = await instance.methods.allowance(owner, spender).call()
      const allowance = fromWei(value, +decimals)

      this.setState({
        tokenAllowanceOutput: {
          allowance
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenApprove = async (input) => {
    try {
      this.requireLogin()

      const { token, spender, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.approve(spender, value).send)({
        from: this.state.accountAddress
      })

      this.setState({
        tokenApproveOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenTransfer = async (input) => {
    try {
      this.requireLogin()

      const { token, to, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.transfer(to, value).send)({
        from: this.state.accountAddress
      })

      this.setState({
        tokenTransferOutput: {
          transactionHash
        }
      })
    } catch (err) {
      // NOTE: The only way for this to fail is if a non-token address is used
      const error = new Error('This is not a token address')
      this.handleError(error)
    }
  }

  tokenTransferFrom = async (input) => {
    try {
      this.requireLogin()

      const { token, from, to, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.transferFrom(from, to, value).send)({
        from: this.state.accountAddress
      })

      this.setState({
        tokenTransferFromOutput: {
          transactionHash
        }
      })
    } catch (err) {
      // NOTE: The only way for this to fail is if a non-token address is used
      const error = new Error('This is not a token address')
      this.handleError(error)
    }
  }

  tokenMint = async (input) => {
    try {
      this.requireLogin()

      const { token, to, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.mint(to, value).send)({
        from: this.state.accountAddress
      })

      this.setState({
        tokenMintOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenBurn = async (input) => {
    try {
      this.requireLogin()

      const { token, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.burn(value).send)({
        from: this.state.accountAddress
      })

      this.setState({
        tokenBurnOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftOwnerOf = async (input) => {
    try {
      const { token, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const owner = await instance.methods.ownerOf(tokenId).call()

      this.setState({
        nftOwnerOfOutput: {
          owner
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftGetApproved = async (input) => {
    try {
      const { token, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const to = await instance.methods.getApproved(tokenId).call()

      this.setState({
        nftGeApprovedOutput: {
          to
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftApprove = async (input) => {
    try {
      this.requireLogin()

      const { token, to, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)

      const transactionHash = await pify(instance.methods.approve(to, tokenId).send)({
        from: this.state.accountAddress
      })

      this.setState({
        nftApproveOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftApproveAll = async (input) => {
    try {
      this.requireLogin()

      const { token, to, approved } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)

      const transactionHash = await pify(instance.methods.setApprovalForAll(to, approved).send)({
        from: this.state.accountAddress
      })

      this.setState({
        nftApproveAllOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftTransferFrom = async (input) => {
    try {
      this.requireLogin()

      const { token, from, to, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const transactionHash = await pify(instance.methods.transferFrom(from, to, tokenId).send)({
        from: this.state.accountAddress
      })

      this.setState({
        nftTransferFromOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftSafeTransferFrom = async (input) => {
    try {
      this.requireLogin()

      const { token, from, to, tokenId, data } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const transactionHash = await pify(instance.methods.safeTransferFrom(from, to, tokenId, data).send)({
        from: this.state.accountAddress
      })

      this.setState({
        nftSafeTransferFromOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  hashPersonalMessage = async (input) => {
    try {
      let { message } = input

      const hash = hashPersonalMessage(message)

      this.setState({
        hashPersonalMessageOutput: {
          hash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  validateSignature = async (input) => {
    try {
      let { account, data, signature } = input
      data = toHex(data, { autoDetectString: false })
      const magicValue = '0x20c13b0b'
      const instance = await new this.web3.eth.Contract(erc1271Abi, account)
      const result = await instance.methods.isValidSignature(data, signature).call()
      const valid = (result.toLowerCase() === magicValue)

      this.setState({
        validateSignatureOutput: {
          valid
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  estimateGas = async (input) => {
    const { from, to, value, data } = input
    const tx = {
      from: toHex(from),
      to: toHex(to),
      value: toHex(value),
      data: toHex(data)
    }

    try {
      const estimatedGas = await this.web3.eth.estimateGas(tx)
      this.setState({
        estimateGasOutput: {
          estimatedGas
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  estimateGasBatch = async (input) => {
    try {
      this.requireLogin()

      const { transactions } = input
      const estimatedGas = await this.web3.currentProvider.estimateGasBatch(transactions)

      this.setState({
        estimateGasBatchOutput: {
          estimatedGas: JSON.stringify(estimatedGas)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  etherConverter = async (input) => {
    const { value, unit } = input

    try {
      const converted = await etherConverter(value, unit)
      this.setState({
        etherConverterOutput: {
          converted
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signMessageWithSigningKey = async (input) => {
    try {
      this.requireLogin()

      const { message } = input
      const signature = await this.web3.currentProvider.signMessageWithSigningKey(message, {
        includeAuthSignature: true
      })

      this.setState({
        signMessageWithSigningKeyOutput: {
          signature
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getEncryptionPublicKey = async () => {
    try {
      const encryptionPublicKey = await this.web3.currentProvider.getEncryptionPublicKey()

      this.setState({
        getEncryptionPublicKeyOutput: {
          encryptionPublicKey
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  encryptMessage = async (input) => {
    try {
      const { message } = input
      const encryptedMessage = await this.web3.currentProvider.encryptMessage(message)

      this.setState({
        encryptMessageOutput: {
          encryptedMessage
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  decryptMessage = async (input) => {
    try {
      const { encryptedMessage } = input
      const message = await this.web3.currentProvider.decryptMessage(encryptedMessage)

      this.setState({
        decryptMessageOutput: {
          message
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  isContractDeployed = async (input) => {
    try {
      const { account } = input
      const deployed = await this.web3.currentProvider.isContractDeployed(account)

      this.setState({
        contractDeployedOutput: {
          deployed
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  widgetToggle = async (input) => {
    try {
      const { enabled } = input
      this.web3.currentProvider.showWidget(enabled)

      this.setState({
        widgetToggleOutput: {
          enabled
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  version = async () => {
    try {
      const version = this.web3.currentProvider.authereum.version()

      this.setState({
        versionOutput: {
          version
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  addFunds = async () => {
    try {
      await this.web3.currentProvider.authereum.addFunds({
        countryCode: 'US',
        tokenSymbol: 'DAI',
        sourceAmount: '25'
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  addressQrCode = async (input) => {
    try {
      const { address } = input
      const qrCodeDataUri = await this.web3.currentProvider.authereum.getAddressQrCodeDataUri(address)

      this.setState({
        addressQrCodeOutput: {
          qrCodeDataUri
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  requireLogin = () => {
    if (!this.web3.currentProvider.isConnected()) {
      throw new Error('Please enable account first')
    }
  }

  handleError = (err) => {
    if (err && err.message) {
      logger.error(err)
    }
  }

  starkAccount = async () => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const starkKey = await starkProvider.getStarkKey()

      this.setState({
        starkAccountOutput: {
          starkKey
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkPublicKey = async () => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const publicKey = await starkProvider.getPublicKey()

      this.setState({
        starkPublicKeyOutput: {
          publicKey
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkEthAddress = async () => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const ethAddress = await starkProvider.getEthAddress()

      this.setState({
        starkEthAddressOutput: {
          ethAddress
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkSignNonce = async (input) => {
    try {
      const { nonce } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const signature = await starkProvider.signNonce(nonce)

      this.setState({
        starkSignNonceOutput: {
          signature
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkSetContract = async (input) => {
    try {
      const { contract } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      await starkProvider.setContractAddress(contract)

      this.setState({
        starkSetContractOutput: {

        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkGetContract = async () => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const contract = await starkProvider.getContractAddress()

      this.setState({
        starkGetContractOutput: {
          contract
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkRegisterUser = async (input) => {
    try {
      this.setState({
        starkRegisterUserOutput: {}
      })

      const { ethKey, operatorSignature } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.registerUser(ethKey, operatorSignature)

      this.setState({
        starkRegisterUserOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.setState({
        starkRegisterUserOutput: {
          error: err.message
        }
      })
    }
  }

  starkDepositEth = async (input) => {
    try {
      let { amount, quantum, vaultId } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.depositEth(vaultId, amount, quantum)

      this.setState({
        starkDepositEthOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkDepositErc20 = async (input) => {
    try {
      let { token, amount, quantum, vaultId } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.depositErc20(vaultId, amount, quantum, token)

      this.setState({
        starkDepositErc20Output: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkDepositErc721 = async (input) => {
    try {
      let { token, tokenId, vaultId } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.depositErc721(vaultId, tokenId, token)

      this.setState({
        starkDepositErc721Output: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkDepositCancel = async (input) => {
    try {
      const { vaultId, assetType } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.cancelDeposit(vaultId, assetType)

      this.setState({
        starkDepositCancelOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkDepositReclaim = async (input) => {
    try {
      const { vaultId, assetType } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.reclaimDeposit(vaultId, assetType)

      this.setState({
        starkDepositReclaimOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkWithdrawEth = async (input) => {
    try {
      const { quantum, recipient } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.withdrawEth(quantum, recipient)

      this.setState({
        starkWithdrawEthOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkWithdrawErc20 = async (input) => {
    try {
      const { token, quantum, recipient } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.withdrawErc20(quantum, token, recipient)

      this.setState({
        starkWithdrawErc20Output: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkWithdrawErc721 = async (input) => {
    try {
      const { token, tokenId, recipient } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.withdrawErc721(tokenId, token, recipient)

      this.setState({
        starkWithdrawErc721Output: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkFullWithdrawalRequest = async (input) => {
    try {
      const { vaultId } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.withdrawFull(vaultId)

      this.setState({
        starkFullWithdrawalRequestOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkWithdrawAndMint = async (input) => {
    try {
      const { assetType, mintingBlob } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.withdrawAndMint(assetType, mintingBlob)

      this.setState({
        starkWithdrawAndMintOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkFreezeRequest = async (input) => {
    try {
      const { vaultId } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.freezeRequest(vaultId)

      this.setState({
        starkFreezeRequestOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkEscape = async (input) => {
    try {
      const { vaultId, assetType, quantizedAmount } = input
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const transactionHash = await starkProvider.escape(vaultId, assetType, quantizedAmount)

      this.setState({
        starkEscapeOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkGetAssetType = async (input) => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const assetType = await starkProvider.getAssetType(input)

      this.setState({
        starkGetAssetTypeOutput: {
          assetType
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkTransfer = async (input) => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const signatureParams = await starkProvider.transfer(input)

      this.setState({
        starkTransferOutput: {
          signature: JSON.stringify(signatureParams, null, 2)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkTransferEth = async (input) => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const signatureParams = await starkProvider.transferEth(input)

      this.setState({
        starkTransferEthOutput: {
          signature: JSON.stringify(signatureParams, null, 2)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkTransferErc20 = async (input) => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const signatureParams = await starkProvider.transferErc20(input)

      this.setState({
        starkTransferErc20Output: {
          signature: JSON.stringify(signatureParams, null, 2)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkTransferErc721 = async (input) => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const signatureParams = await starkProvider.transferErc721(input)

      this.setState({
        starkTransferErc721Output: {
          signature: JSON.stringify(signatureParams, null, 2)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  starkCreateOrder = async (input) => {
    try {
      const starkProvider = this.web3.currentProvider.authereum.getStarkProvider()
      const signatureParams = await starkProvider.createOrder(input)

      this.setState({
        starkCreateOrderOutput: {
          signature: JSON.stringify(signatureParams, null, 2)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  zkSyncDeposit = async (input) => {
    try {
      const zkSyncProvider = this.web3.currentProvider.authereum.getZkSyncProvider()
      const transactionHash = await zkSyncProvider.deposit(input)

      this.setState({
        zkSyncDepositOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  zkSyncWithdraw = async (input) => {
    try {
      const zkSyncProvider = this.web3.currentProvider.authereum.getZkSyncProvider()
      const transactionHash = await zkSyncProvider.withdraw(input)

      this.setState({
        zkSyncWithdrawOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  zkSyncTransfer = async (input) => {
    try {
      const zkSyncProvider = this.web3.currentProvider.authereum.getZkSyncProvider()
      const transactionHash = await zkSyncProvider.transfer(input)

      this.setState({
        zkSyncTransferOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }
}

export default App
