import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      assetStandardSell: localStorage.getItem('starkCreateOrder::assetStandardSell') || 'ETH',
      tokenAddressSell: localStorage.getItem('starkCreateOrder::tokenAddressSell') || '',
      quantumSell: localStorage.getItem('starkCreateOrder::quantumSell') || '10',
      amountSell: localStorage.getItem('starkCreateOrder::amountSell') || '1',
      vaultSell: localStorage.getItem('starkCreateOrder::vaultSell') || '34',

      assetStandardBuy: localStorage.getItem('starkCreateOrder::assetStandardBuy') || 'ETH',
      tokenAddressBuy: localStorage.getItem('starkCreateOrder::tokenAddressBuy') || '',
      quantumBuy: localStorage.getItem('starkCreateOrder::qauntumBuy') || '10',
      amountBuy: localStorage.getItem('starkCreateOrder::amountBuy') || '1',
      vaultBuy: localStorage.getItem('starkCreateOrder::vaultBuy') || '35',

      nonce: localStorage.getItem('starkCreateOrder::nonce') || '0',
      expirationTimestamp: localStorage.getItem('starkCreateOrder::expirationTimestamp') || '438953'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Create Order'}
        subheader={'starkProvider.createOrder({...params})'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Asset standard (sell)'
            placeholder='Asset standard (sell)'
            value={this.state.assetStandardSell}
            onChange={event => {
              const assetStandardSell = event.target.value
              this.setState({ assetStandardSell })
              localStorage.setItem('starkCreateOrder::assetStandardSell', assetStandardSell)
            }}
          />
          <TextFieldFormGroup
            label='Token address (sell)'
            placeholder='Token address (sell)'
            value={this.state.tokenAddressSell}
            onChange={event => {
              const tokenAddressSell = event.target.value
              this.setState({ tokenAddressSell })
              localStorage.setItem('starkCreateOrder::tokenAddressSell', tokenAddressSell)
            }}
          />
          <TextFieldFormGroup
            label='Quantum (sell)'
            placeholder='Quantum (sell)'
            value={this.state.quantumSell}
            onChange={event => {
              const quantumSell = event.target.value
              this.setState({ quantumSell })
              localStorage.setItem('starkCreateOrder::quantumSell', quantumSell)
            }}
          />
          <TextFieldFormGroup
            label='Amount (sell)'
            placeholder='Amount (sell)'
            value={this.state.amountSell}
            onChange={event => {
              const amountSell = event.target.value
              this.setState({ amountSell })
              localStorage.setItem('starkCreateOrder::amountSell', amountSell)
            }}
          />
          <TextFieldFormGroup
            label='Vault ID (sell)'
            placeholder='Vault ID (sell)'
            value={this.state.vaultSell}
            onChange={event => {
              const vaultSell = event.target.value
              this.setState({ vaultSell })
              localStorage.setItem('starkCreateOrder::vaultSell', vaultSell)
            }}
          />
          <TextFieldFormGroup
            label='Asset standard (buy)'
            placeholder='Asset standard (buy)'
            value={this.state.assetStandardBuy}
            onChange={event => {
              const assetStandardBuy = event.target.value
              this.setState({ assetStandardBuy })
              localStorage.setItem('starkCreateOrder::assetStandardBuy', assetStandardBuy)
            }}
          />
          <TextFieldFormGroup
            label='Token address (buy)'
            placeholder='Token address (buy)'
            value={this.state.tokenAddressBuy}
            onChange={event => {
              const tokenAddressBuy = event.target.value
              this.setState({ tokenAddressBuy })
              localStorage.setItem('starkCreateOrder::tokenAddressBuy', tokenAddressBuy)
            }}
          />
          <TextFieldFormGroup
            label='Quantum (buy)'
            placeholder='Quantum (buy)'
            value={this.state.quantumBuy}
            onChange={event => {
              const quantumBuy = event.target.value
              this.setState({ quantumBuy })
              localStorage.setItem('starkCreateOrder::quantumBuy', quantumBuy)
            }}
          />
          <TextFieldFormGroup
            label='Amount (buy)'
            placeholder='Amount (buy)'
            value={this.state.amountBuy}
            onChange={event => {
              const amountBuy = event.target.value
              this.setState({ amountBuy })
              localStorage.setItem('starkCreateOrder::amountBuy', amountBuy)
            }}
          />
          <TextFieldFormGroup
            label='Vault ID (buy)'
            placeholder='Vault ID (buy)'
            value={this.state.vaultBuy}
            onChange={event => {
              const vaultBuy = event.target.value
              this.setState({ vaultBuy })
              localStorage.setItem('starkCreateOrder::vaultBuy', vaultBuy)
            }}
          />
          <TextFieldFormGroup
            label='Nonce'
            placeholder='Nonce'
            value={this.state.nonce}
            onChange={event => {
              const nonce = event.target.value
              this.setState({ nonce })
              localStorage.setItem('starkCreateOrder::nonce', nonce)
            }}
          />
          <TextFieldFormGroup
            label='Expiration Timestamp'
            placeholder='Expiration Timestamp'
            value={this.state.expirationTimestamp}
            onChange={event => {
              const expirationTimestamp = event.target.value
              this.setState({ expirationTimestamp })
              localStorage.setItem('starkCreateOrder::expirationTimestamp', expirationTimestamp)
            }}
          />
          <TextValueFormGroup
            label='Signature'
            whitespace='break-spaces'
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Create order signature
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {
      assetStandardSell,
      tokenAddressSell,
      quantumSell,
      amountSell,
      vaultSell,

      assetStandardBuy,
      tokenAddressBuy,
      quantumBuy,
      amountBuy,
      vaultBuy,

      nonce,
      expirationTimestamp
    } = this.state

    const input = {
      sell: {
        type: assetStandardSell,
        data: {
          quantum: quantumSell,
          tokenAddress: tokenAddressSell
        },
        amount: amountSell,
        vaultId: vaultSell
      },
      buy: {
        type: assetStandardBuy,
        data: {
          quantum: quantumBuy,
          tokenAddress: tokenAddressBuy
        },
        amount: amountBuy,
        vaultId: vaultBuy
      },
      nonce,
      expirationTimestamp
    }
    this.props.onSubmit(input)
  }
}
