import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ErrorTextValueFormGroup from 'src/components/ErrorTextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ethKey: localStorage.getItem('starkRegister::ethKey') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      operatorSignature: localStorage.getItem('starkRegister::operatorSignature') || '0xae50f29764c96ff5d1554f1d0605bdb992a62700eb8a0b2ffd711da6d6fd90781dbfbb8918c98c15e9fd6251ad89d55e30c20060cf300b51929129726718b6711c'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Register User'}
        subheader={'starkProvider.registerUser(ethKey, operatorSignature)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Eth key (use your account address)'
            placeholder='Eth key'
            value={this.state.ethKey}
            onChange={event => {
              const ethKey = event.target.value
              localStorage.setItem('starkRegister::ethKey', ethKey)
              this.setState({ ethKey })
            }}
          />
          <TextFieldFormGroup
            label='Operator signature'
            placeholder='Operator signature'
            value={this.state.operatorSignature}
            onChange={event => {
              const operatorSignature = event.target.value
              localStorage.setItem('starkRegister::operatorSignature', operatorSignature)
              this.setState({ operatorSignature })
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ErrorTextValueFormGroup
            label='Error'
            value={this.props.error}
          />
          <ButtonFormGroup>
            Register
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
