import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      message: localStorage.getItem('sign::message') || 'hello world'
    }
  }

  render () {
    return (
      <Card
        title={'Sign'}
        subheader={'web3.eth.sign(data, account)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Message'
            multiline
            placeholder='Enter message'
            value={this.state.message}
            onChange={event => {
              const message = event.target.value
              this.setState({ message })
              localStorage.setItem('sign::message', message)
            }}
          />
          <TextValueFormGroup
            label='Signature'
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Sign message
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
