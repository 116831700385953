import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('nftGetApproved::token') || '0x4f41d10f7e67fd16bde916b4a6dc3dd101c57394',
      tokenId: localStorage.getItem('nftGetApproved::tokenId') || '8117'
    }
  }

  render () {
    return (
      <Card
        title={'ERC721: Get Approved'}
        subheader={'token.getApproved(tokenId)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('nftGetApproved::token', token)
              this.setState({ token })
            }}
          />
          <TextFieldFormGroup
            label='Token ID'
            placeholder='Enter token ID'
            value={this.state.tokenId}
            onChange={event => {
              const tokenId = event.target.value
              localStorage.setItem('nftGetApproved::tokenId', tokenId)
              this.setState({ tokenId })
            }}
          />
          <TextValueFormGroup
            label='To'
            value={this.props.to}
          />
          <ButtonFormGroup>
            Get approved
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
