import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      account: localStorage.getItem('contractDeployed::account') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b'
    }
  }

  render () {
    const { deployed } = this.props

    return (
      <Card
        title={'Contract Deployed'}
        subheader={'web3.currentProvider.isContractDeployed(account?)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Account'
            placeholder='Enter account address'
            value={this.state.account}
            onChange={event => {
              const account = event.target.value
              this.setState({ account })
              localStorage.setItem('contractDeployed::account', account)
            }}
          />
          <TextValueFormGroup
            label='Deployed'
            value={`${deployed !== undefined ? deployed : ''}`}
          />
          <ButtonFormGroup>
            Check
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
