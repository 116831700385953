import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      account: localStorage.getItem('validateSignature::account') || '0x99bd0006D13542A0917Cf8F2F986Ca7667b84268',
      data: localStorage.getItem('validateSignature::data') || '0x47173285a8d7341e5e972fc677286384f802f8ef42a5ec5f03bbfa254cb01fad',
      signature: localStorage.getItem('validateSignature::signature') || '0x0304494527023df3a811f5ad61aa35177a4455eb4bf098561f9380a574915f4c1ff4a5fc653afdfc086dcc9662848097703d18b82156618ccec1e5c9da7623e51b4760269d07f9a074dc2d6ab10cf52ff77852662e40fbb4b27289126a5bb538271e147c0952204161d710bb070a6e470b0b1ef65d11f1dc074e235e3dfaef00ae1b'
    }
  }

  render () {
    return (
      <Card
        title={'EIP1271: Validate Signature'}
        subheader={'contract.isValidSignature(data, signature)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Account'
            placeholder='Enter account address'
            value={this.state.account}
            onChange={event => {
              const account = event.target.value
              localStorage.setItem('validateSignature::account', account)
              this.setState({ account })
            }}
          />
          <TextFieldFormGroup
            label='Data'
            multiline
            placeholder='Enter data'
            value={this.state.data}
            onChange={event => {
              const data = event.target.value
              this.setState({ data })
              localStorage.setItem('validateSignature::data', data)
            }}
          />
          <TextFieldFormGroup
            label='Signature'
            placeholder='Enter signature'
            value={this.state.signature}
            onChange={event => {
              const signature = event.target.value
              this.setState({ signature })
              localStorage.setItem('validateSignature::signature', signature)
            }}
          />
          <TextValueFormGroup
            label='Valid'
            value={`${this.props.valid !== undefined ? this.props.valid : '-'}`.toUpperCase()}
          />
          <ButtonFormGroup>
            Validate
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
