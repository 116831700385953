import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      vaultId: localStorage.getItem('starkDepositReclaim::vaultId') || '15',
      assetType: localStorage.getItem('starkDepositReclaim::assetType') || '0x4b744eda38322858d42ba43046badd5bd91e94844c0b7c47a4975d8b5b77b5'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Reclaim Deposit'}
        subheader={'starkProvider.reclaimDeposit(vaultId, assetType)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Vault ID'
            placeholder='Vault ID'
            value={this.state.vaultId}
            onChange={event => {
              const vaultId = event.target.value
              this.setState({ vaultId })
              localStorage.setItem('starkDepositReclaim::vaultId', vaultId)
            }}
          />
          <TextFieldFormGroup
            label='Asset type'
            placeholder='Asset type'
            value={this.state.assetType}
            onChange={event => {
              const assetType = event.target.value
              this.setState({ assetType })
              localStorage.setItem('starkDepositReclaim::assetType', assetType)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Reclaim Deposit
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
