import React from 'react'
import styled from 'styled-components'
import updateQueryParam from '@authereum/utils/core/updateQueryParam'
import removeQueryParam from '@authereum/utils/core/removeQueryParam'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { version as latestVersion } from 'authereum'
import NetworkMenu from 'src/components/NetworkMenu'
import { webUri, authereumVersions } from 'src/config'

const UI = {
  Container: styled.div`
    padding: 3em;
    display: flex;
    justify-content: space-between;
    @media(max-width: 700px) {
      flex-direction: column;
    }
  `,
  Group1: styled.div`
    @media(max-width: 700px) {
      text-align: center;
      margin-bottom: 2em;
    }
  `,
  Group2: styled.div`
    display: flex;
    align-items: center;

    @media(max-width: 700px) {
      text-align: center;
      flex-direction: column;
    }
  `,
  ImageContainer: styled.div`
    max-width: 15em;
    margin-bottom: 1em;
    position: relative;
    @media(max-width: 700px) {
      margin: 0 auto 1em auto;
    }
  `,
  Image: styled.img`
    width: 100%;
  `,
  Nav: styled.div`
    display: flex;
    margin-right: 1em;

    a {
      display: inline-block;
      margin-right: 1em;
    }

    @media(max-width: 700px) {
      flex-direction: column;
      margin-bottom: 1em;
    }
  `,
  BetaLabel: styled.div`
    font-size: 1em;
    color: #becad5;
    font-weight: normal;
    position: absolute;
    bottom: -0.2em;
    left: 3.5em;
    text-transform: lowercase;
  `,
  VersionSelector: styled.div`
    padding-top: 0.5em;
    padding-left: 1em;

    select {
      font-size: 0.8em;
    }

    @media(max-width: 700px) {
      padding-left: 0;
      margin: 0 auto;
    }
  `
}

export default class Component extends React.Component {
  render () {
    const { networkName, sdkVersion } = this.props

    return (
      <UI.Container>
        <UI.Group1>
          <UI.ImageContainer>
            <a href={webUri}>
              <UI.Image src='https://assets.authereum.com/authereum_text_logo.svg' alt='Authereum Demo' />
            </a>
            <UI.BetaLabel>Beta</UI.BetaLabel>
          </UI.ImageContainer>
          <Typography
            variant='body1'
            style={{
              paddingLeft: '1em'
            }}>
            <Link
              href='https://npmjs.com/authereum'
              target='_blank'
              rel='noopener noreferrer'>authereum@{sdkVersion}</Link> kitchen sink demo
          </Typography>
          {this.renderVersionsDropdown()}
        </UI.Group1>
        <UI.Group2>
          <UI.Nav>
            <Link
              href='https://docs.authereum.com'
              target='_blank'
              rel='noopener noreferrer'>Documentation</Link>
          </UI.Nav>
          <div>
            <NetworkMenu networkName={networkName} />
          </div>
        </UI.Group2>
      </UI.Container>
    )
  }

  renderVersionsDropdown () {
    const { sdkVersion } = this.props

    const options = authereumVersions
      .filter(version => version !== latestVersion)
      .map(version => {
        return <option value={version} key={version}>{version}</option>
      })

    return (
      <UI.VersionSelector>
        <select
          value={sdkVersion}
          onChange={this.handleSdkVersionChange}>
          <option value={latestVersion} key={latestVersion}>latest - {latestVersion}</option>
          {options}
        </select>
      </UI.VersionSelector>
    )
  }

  handleSdkVersionChange = (event) => {
    event.preventDefault()
    const sdkVersion = event.currentTarget.value

    let url = updateQueryParam('version', sdkVersion)

    if (sdkVersion === latestVersion) {
      url = removeQueryParam('version')
    }

    window.location.href = url
  }
}
