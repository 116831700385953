import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const visit = (networkName) => {
  return () => {
    window.location.href = `https://${networkName !== 'mainnet' ? `${networkName}.` : ''}demo.authereum.com`
  }
}

export default function MenuPopupState (props) {
  const { networkName } = props

  return (
    <PopupState variant='popover' popupId='demo-popup-menu'>
      {popupState => (
        <React.Fragment>
          <Button variant='contained' color='primary' {...bindTrigger(popupState)}>
            Network: {networkName} <ArrowDropDownIcon />
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={visit('mainnet')} selected={networkName === 'mainnet'}>Mainnet</MenuItem>
            <MenuItem onClick={visit('kovan')} selected={networkName === 'kovan'}>Kovan</MenuItem>
            <MenuItem onClick={visit('rinkeby')} selected={networkName === 'rinkeby'}>Rinkeby</MenuItem>
            <MenuItem onClick={visit('goerli')} selected={networkName === 'goerli'}>Goerli</MenuItem>
            <MenuItem onClick={visit('ropsten')} selected={networkName === 'ropsten'}>Ropsten</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
