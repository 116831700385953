import { networkName } from '../config'

const prefix = `@authereum/example/${networkName}:::`

// eslint-disable-next-line
const storage = window.localStorage

export const setItem = (key, value) => {
  return storage.setItem(prefix + key, value)
}

export const getItem = (key) => {
  return storage.getItem(prefix + key)
}
