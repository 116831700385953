import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      message: localStorage.getItem('encryptMessage::message') || 'hello world'
    }
  }

  render () {
    const { encryptedMessage } = this.props

    return (
      <Card
        title={'Encrypt Message'}
        subheader={'web3.currentProvider.encryptMessage(message)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Message'
            placeholder='Enter message'
            value={this.state.message}
            onChange={event => {
              const message = event.target.value
              this.setState({ message })
              localStorage.setItem('encryptMessage::message', message)
            }}
          />
          <TextValueFormGroup
            label='Encrypted message'
            value={`${encryptedMessage !== undefined ? encryptedMessage : ''}`}
          />
          <ButtonFormGroup>
            Encrypt message
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
