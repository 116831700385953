import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('starkWithdrawErc721::token') || '0x6B5E013ba22F08ED46d33Fa6d483Fd60e001262e',
      tokenId: localStorage.getItem('starkWithdrawErc721::tokenId') || '1234',
      recipient: localStorage.getItem('starkWithdrawErc721::recipient') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Withdraw ERC721'}
        subheader={'starkProvider.withdrawErc721(tokenId, token, recipient)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Token'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              this.setState({ token })
              localStorage.setItem('starkWithdrawErc721::token', token)
            }}
          />
          <TextFieldFormGroup
            label='Token ID'
            placeholder='Enter token ID'
            value={this.state.tokenId}
            onChange={event => {
              const tokenId = event.target.value
              this.setState({ tokenId })
              localStorage.setItem('starkWithdrawErc721::tokenId', tokenId)
            }}
          />
          <TextFieldFormGroup
            label='Recipient'
            placeholder='Recipient'
            value={this.state.recipient}
            onChange={event => {
              const recipient = event.target.value
              this.setState({ recipient })
              localStorage.setItem('starkWithdrawErc721::recipient', recipient)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Withdraw
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
