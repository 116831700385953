import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('starkDepositErc721::token') || '0x6B5E013ba22F08ED46d33Fa6d483Fd60e001262e',
      tokenId: localStorage.getItem('starkDepositErc721::tokenId') || '1234',
      vaultId: localStorage.getItem('starkDepositErc721::vaultId') || '15'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Deposit ERC721'}
        subheader={'starkProvider.depositErc721(vaultId, tokenId, token)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              this.setState({ token })
              localStorage.setItem('starkDepositErc721::token', token)
            }}
          />
          <TextFieldFormGroup
            label='Token ID'
            placeholder='Token ID'
            value={this.state.tokenId}
            onChange={event => {
              const tokenId = event.target.value
              this.setState({ tokenId })
              localStorage.setItem('starkDepositErc721::tokenId', tokenId)
            }}
          />
          <TextFieldFormGroup
            label='Vault ID'
            placeholder='Vault ID'
            value={this.state.vaultId}
            onChange={event => {
              const vaultId = event.target.value
              this.setState({ vaultId })
              localStorage.setItem('starkDepositErc721::vaultId', vaultId)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Deposit
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
