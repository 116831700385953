import React from 'react'
import Card from 'src/components/Card'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('nftApproveAll::token') || '0x4f41d10f7e67fd16bde916b4a6dc3dd101c57394',
      to: localStorage.getItem('nftApproveAll::to') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      approved: localStorage.getItem('nftApproveAll::approved') || true
    }
  }

  render () {
    return (
      <Card
        title={'ERC721: Approve All'}
        subheader={`token.setApprovalForAll(to, ${this.state.approved})`}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('nftApproveAll::token', token)
              this.setState({ token })
            }}
          />
          <TextFieldFormGroup
            label='To'
            placeholder='Enter to address'
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('nftApproveAll::to', to)
              this.setState({ to })
            }}
          />
          <FormGroup row>
            <FormControl
              fullWidth
              margin='dense'>
              <FormLabel
                style={{
                  fontSize: '0.8rem',
                  marginBottom: '0.3rem'
                }}
                color='secondary'>
                Approve
              </FormLabel>
              <Select
                value={`${this.state.approved}`}
                onChange={this.handleApproveChange}
                variant='outlined'
                style={{
                  borderTop: '1px solid #d0d3d4',
                  borderRight: '1px solid #d0d3d4',
                  borderLeft: '1px solid #d0d3d4',
                  padding: '0.8em'
                }}>
                <MenuItem value={'true'}>true</MenuItem>
                <MenuItem value={'false'}>false</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Approve all
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleApproveChange = (event) => {
    event.preventDefault()

    const approved = (event.target.value === 'true')
    this.setState({ approved })
    localStorage.setItem('nftApproveAll::approved', approved)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
