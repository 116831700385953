import React from 'react'
import Link from '@material-ui/core/Link'
import ExternalLinkIcon from '@material-ui/icons/Launch'

export default class Component extends React.Component {
  render () {
    return (
      <Link
        href={this.props.href}
        target='_blank'
        rel="noopener noreferrer'"
      >
        <span>{this.props.children}</span>
        <ExternalLinkIcon
          style={{ verticalAlign: 'middle' }}
          data-icon />
      </Link>
    )
  }
}
