import React from 'react'
import Typography from '@material-ui/core/Typography'
import Card from 'src/components/Card'
import ButtonFormGroup from 'src/components/ButtonFormGroup'

export default class Component extends React.Component {
  render () {
    return (
      <Card
        title={'Add Funds'}
        subheader={'authereum.addFunds()'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <Typography
            variant='body2'
            style={{
              marginBottom: '1em'
            }}>
            Show fiat on-ramp modal
          </Typography>
          <ButtonFormGroup>
            Add funds
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
