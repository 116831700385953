import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('tokenApprove::token') || '0x7d669A64deb8a4A51eEa755bb0E19FD39CE25Ae9',
      spender: localStorage.getItem('tokenApprove::spender') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      amount: localStorage.getItem('tokenApprove::amount') || '0.1'
    }
  }

  render () {
    return (
      <Card
        title={'ERC20: Approve'}
        subheader={'token.approve(spender, amount)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('tokenApprove::token', token)
              this.setState({ token })
            }}
          />
          <TextFieldFormGroup
            label='Spender'
            placeholder='Enter spender address'
            value={this.state.spender}
            onChange={event => {
              const spender = event.target.value
              this.setState({ spender })
              localStorage.setItem('tokenApprove::spender', spender)
            }}
          />
          <TextFieldFormGroup
            label='Amount'
            placeholder='Enter amount'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              this.setState({ amount })
              localStorage.setItem('tokenApprove::amount', amount)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Approve
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
