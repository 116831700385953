import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('tokenMint::token') || '0x7d669A64deb8a4A51eEa755bb0E19FD39CE25Ae9',
      to: localStorage.getItem('tokenMint::to') || '0x96E782B8CCC76F239043A3aE5Ae17cbB67399aA2',
      amount: localStorage.getItem('tokenMint::amount') || '0.1'
    }
  }

  render () {
    return (
      <Card
        title={'ERC20: Mint'}
        subheader={'token.mint(to, amount)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('tokenMint::token', token)
              this.setState({ token })
            }}
          />
          <TextFieldFormGroup
            label='To'
            placeholder='Enter to address'
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('tokenMint::to', to)
              this.setState({ to })
            }}
          />
          <TextFieldFormGroup
            label='Amount'
            placeholder='Enter amount'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              localStorage.setItem('tokenMint::amount', amount)
              this.setState({ amount })
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Mint
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
