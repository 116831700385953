import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      encryptedMessage: localStorage.getItem('decryptMessage::encryptedMessage') || ''
    }
  }

  render () {
    const { message } = this.props

    return (
      <Card
        title={'Decrypt Message'}
        subheader={'web3.currentProvider.decryptMessage(ciphertext)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Encrypted message'
            placeholder='Enter encrypted message'
            value={this.state.encryptedMessage}
            onChange={event => {
              const encryptedMessage = event.target.value
              this.setState({ encryptedMessage })
              localStorage.setItem('decryptMessage::encryptedMessage', encryptedMessage)
            }}
          />
          <TextValueFormGroup
            label='Decrypted message'
            value={`${message !== undefined ? message : ''}`}
          />
          <ButtonFormGroup>
            Decrypt message
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
