import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      transactionHash: localStorage.getItem('getTransactionReceipt::transactionHash') || '0x82c0fe7a4f293d978c3476ac8d7462df392339aeb61a4a7be684965edfc76180'
    }
  }

  render () {
    return (
      <Card
        title={'Transaction Receipt'}
        subheader={'web3.currentProvider.getTransactionReceipt(txHash)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Transaction hash'
            placeholder='Enter transaction hash'
            value={this.state.transactionHash}
            onChange={event => {
              const transactionHash = event.target.value
              this.setState({ transactionHash })
              localStorage.setItem('getTransactionReceipt::transactionHash', transactionHash)
            }}
          />
          <TextValueFormGroup
            label='Receipt'
            whitespace='pre'
            value={this.props.receipt ? JSON.stringify(this.props.receipt, null,
              2) : ''}
          />
          <ButtonFormGroup>
            Get transaction receipt
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
