import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'

export default class Component extends React.Component {
  render () {
    const variant = this.props.variant ? this.props.variant : 'contained'

    return (
      <FormGroup row>
        <FormControl
          fullWidth
          margin='dense'>
          <Button
            variant={variant}
            color='primary'
            type='submit'
            {...this.props}>
            {this.props.children}
          </Button>
        </FormControl>
      </FormGroup>
    )
  }
}
