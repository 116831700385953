import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      assetType: localStorage.getItem('starkWithdrawAndMint::assetType') || '0x1f6d8eecef9a4b7f7bf5c92dfdfcc9892f114ae611b4783ba67dc1b2adce36a',
      mintingBlob: localStorage.getItem('starkWithdrawAndMint::mintingBlob') || '0x'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Withdraw and Mint'}
        subheader={'starkProvider.withdrawAndMint(assetType, mintingBlob)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Asset Type'
            placeholder='Asset Type'
            value={this.state.assetType}
            onChange={event => {
              const assetType = event.target.value
              localStorage.setItem('starkWithdrawAndMint::assetType', assetType)
              this.setState({ assetType })
            }}
          />
          <TextFieldFormGroup
            label='Minting blob'
            placeholder='Minting blob'
            value={this.state.mintingBlob}
            onChange={event => {
              const mintingBlob = event.target.value
              this.setState({ mintingBlob })
              localStorage.setItem('starkWithdrawAndMint::mintingBlob', mintingBlob)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Withdraw and mint
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
