import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('tokenAllowance::token') || '0x7d669A64deb8a4A51eEa755bb0E19FD39CE25Ae9',
      owner: localStorage.getItem('tokenAllowance::owner') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      spender: localStorage.getItem('tokenAllowance::spender') || '0x96E782B8CCC76F239043A3aE5Ae17cbB67399aA2'
    }
  }

  render () {
    return (
      <Card
        title={'ERC20: Allowance'}
        subheader={'token.allowance(owner, spender)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('tokenAllowance::token', token)
              this.setState({ token })
            }}
          />
          <TextFieldFormGroup
            label='Owner'
            placeholder='Enter token owner'
            value={this.state.owner}
            onChange={event => {
              const owner = event.target.value
              localStorage.setItem('tokenAllowance::owner', owner)
              this.setState({ owner })
            }}
          />
          <TextFieldFormGroup
            label='Spender'
            placeholder='Enter token spender'
            value={this.state.spender}
            onChange={event => {
              const spender = event.target.value
              this.setState({ spender })
              localStorage.setItem('tokenAllowance::spender', spender)
            }}
          />
          <TextValueFormGroup
            label='Allowance'
            value={this.props.allowance}
          />
          <ButtonFormGroup>
            Get allowance
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
