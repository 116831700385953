import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      vaultId: localStorage.getItem('starkFullWithdrawalRequest::vaultId') || '3'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Full Withdrawal Request'}
        subheader={'starkProvider.fullWithdrawalRequest(vaultId)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Vault ID'
            placeholder='Vault ID'
            value={this.state.vaultId}
            onChange={event => {
              const vaultId = event.target.value
              this.setState({ vaultId })
              localStorage.setItem('starkFullWithdrawalRequest::vaultId', vaultId)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Full withdrawal request
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
