import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('starkWithdrawErc20::token') || '0x0d9c8723b343a8368bebe0b5e89273ff8d712e3c',
      quantum: localStorage.getItem('starkWithdrawErc20::quantum') || '1000',
      recipient: localStorage.getItem('starkWithdrawErc20::recipient') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Withdraw ERC20'}
        subheader={'starkProvider.withdrawErc20(quantum, token, recipient)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              this.setState({ token })
              localStorage.setItem('starkWithdrawErc20::token', token)
            }}
          />
          <TextFieldFormGroup
            label='Quantum'
            placeholder='Enter quantum'
            value={this.state.quantum}
            onChange={event => {
              const quantum = event.target.value
              this.setState({ quantum })
              localStorage.setItem('starkWithdrawErc20::quantum', quantum)
            }}
          />
          <TextFieldFormGroup
            label='Recipient'
            placeholder='Recipient'
            value={this.state.recipient}
            onChange={event => {
              const recipient = event.target.value
              this.setState({ recipient })
              localStorage.setItem('starkWithdrawErc20::recipient', recipient)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Withdraw
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
