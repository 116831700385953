import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      from: localStorage.getItem('estimateGas::from') || '0x0984c065a1a3d44c3C36CFb4e529a1dA89282b90',
      to: localStorage.getItem('estimateGas::to') || '0xa5025faba6e70b84f74e9b1113e5f7f4e7f4859f',
      value: localStorage.getItem('estimateGas::value') || '0x470de4df820000',
      data: localStorage.getItem('estimateGas::data') || '0x4ad6b31c0000000000000000000000007d669a64deb8a4a51eea755bb0e19fd39ce25ae900000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000002000000000000000000000000a91ec3422e61b952a682e6722fab6f362042af5e00000000000000000000000078fba17a4bfd0cf94d0b3984b26cf7869a2ab24b000000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000001'
    }
  }

  render () {
    return (
      <Card
        title={'Estimate Gas'}
        subheader={'web3.eth.estimateGas({from, to, value, data})'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='From'
            placeholder='Enter from address'
            value={this.state.from}
            onChange={event => {
              const from = event.target.value
              localStorage.setItem('estimateGas::from', from)
              this.setState({ from })
            }}
          />
          <TextFieldFormGroup
            label='To'
            placeholder='Enter to address'
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('estimateGas::to', to)
              this.setState({ to })
            }}
          />
          <TextFieldFormGroup
            label='Value'
            placeholder='Enter value (wei)'
            value={this.state.value}
            onChange={event => {
              const value = event.target.value
              localStorage.setItem('estimateGas::value', value)
              this.setState({ value })
            }}
          />
          <TextFieldFormGroup
            label='Data'
            multiline
            rowsMax={10}
            placeholder='Enter data'
            value={this.state.data}
            onChange={event => {
              const data = event.target.value
              this.setState({ data })
              localStorage.setItem('estimateGas::data', data)
            }}
          />
          <TextValueFormGroup
            label='Estimated gas'
            value={this.props.estimatedGas}
          />
          <ButtonFormGroup>
            Estimate gas
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
