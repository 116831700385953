import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      amount: localStorage.getItem('starkTransferErc20::amount') || '10',
      assetContractAddress: localStorage.getItem('starkTransferErc20::amount') || '0x0d9c8723b343a8368bebe0b5e89273ff8d712e3c',
      quantum: localStorage.getItem('starkTransferErc20::quantum') || '1000',
      nonce: localStorage.getItem('starkTransferErc20::nonce') || '1',
      senderVaultId: localStorage.getItem('starkTransferErc20::senderVaultId') || '34',
      receiverVaultId: localStorage.getItem('starkTransferErc20::receiverVaultId') || '35',
      receiverKey: localStorage.getItem('starkTransferErc20::receiverKey') || '0x04887f4c82218a6d13c6a0a249413e6e53b6910de5f0e6b5d85cfef1db04553b',
      expirationTimestamp: localStorage.getItem('starkTransferErc20::expirationTimestamp') || '438953',
      condition: localStorage.getItem('starkTransferErc20::condition') || ''
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Transfer ERC20'}
        subheader={'starkProvider.transferErc20(params)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Amount'
            placeholder='Amount'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              this.setState({ amount })
              localStorage.setItem('starkTransferErc20::amount', amount)
            }}
          />
          <TextFieldFormGroup
            label='Quantum'
            placeholder='Quantum'
            value={this.state.quantum}
            onChange={event => {
              const quantum = event.target.value
              this.setState({ quantum })
              localStorage.setItem('starkTransferErc20::quantum', quantum)
            }}
          />
          <TextFieldFormGroup
            label='Token'
            placeholder='Token'
            value={this.state.assetContractAddress}
            onChange={event => {
              const assetContractAddress = event.target.value
              this.setState({ assetContractAddress })
              localStorage.setItem('starkTransferErc20::token', assetContractAddress)
            }}
          />
          <TextFieldFormGroup
            label='Sender Vault ID'
            placeholder='Sender vault ID'
            value={this.state.senderVaultId}
            onChange={event => {
              const senderVaultId = event.target.value
              this.setState({ senderVaultId })
              localStorage.setItem('starkTransferErc20::senderVaultId', senderVaultId)
            }}
          />
          <TextFieldFormGroup
            label='Receiver Vault ID'
            placeholder='Receiver vault ID'
            value={this.state.receiverVaultId}
            onChange={event => {
              const receiverVaultId = event.target.value
              this.setState({ receiverVaultId })
              localStorage.setItem('starkTransferErc20::receiverVaultId', receiverVaultId)
            }}
          />
          <TextFieldFormGroup
            label='Receiver key'
            placeholder='Receiver key'
            value={this.state.receiverKey}
            onChange={event => {
              const receiverKey = event.target.value
              this.setState({ receiverKey })
              localStorage.setItem('starkTransferErc20::receiverKey', receiverKey)
            }}
          />
          <TextFieldFormGroup
            label='Nonce'
            placeholder='Nonce'
            value={this.state.nonce}
            onChange={event => {
              const nonce = event.target.value
              this.setState({ nonce })
              localStorage.setItem('starkTransferErc20::nonce', nonce)
            }}
          />
          <TextFieldFormGroup
            label='Expiration Timestamp'
            placeholder='Expiration Timestamp'
            value={this.state.expirationTimestamp}
            onChange={event => {
              const expirationTimestamp = event.target.value
              this.setState({ expirationTimestamp })
              localStorage.setItem('starkTransferErc20::expirationTimestamp', expirationTimestamp)
            }}
          />
          <TextFieldFormGroup
            label='Condition'
            placeholder='Condition'
            value={this.state.condition}
            onChange={event => {
              const condition = event.target.value
              this.setState({ condition })
              localStorage.setItem('starkTransferErc20::condition', condition)
            }}
          />
          <TextValueFormGroup
            label='Signature'
            whitespace='break-spaces'
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Create transfer signature
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
