import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('starkDepositErc20::token') || '0x0d9c8723b343a8368bebe0b5e89273ff8d712e3c',
      amount: localStorage.getItem('starkDepositErc20::amount') || '420000',
      quantum: localStorage.getItem('starkDepositErc20::quantum') || '1000',
      vaultId: localStorage.getItem('starkDepositErc20::vaultId') || '15'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Deposit ERC20'}
        subheader={'starkProvider.depositErc20(vaultId, amount, quantum, token)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              this.setState({ token })
              localStorage.setItem('starkDepositErc20::token', token)
            }}
          />
          <TextFieldFormGroup
            label='Amount'
            placeholder='Enter amount'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              localStorage.setItem('starkDepositErc20::amount', amount)
              this.setState({ amount })
            }}
          />
          <TextFieldFormGroup
            label='Quantum'
            placeholder='Enter quantum'
            value={this.state.quantum}
            onChange={event => {
              const quantum = event.target.value
              localStorage.setItem('starkDepositErc20::quantum', quantum)
              this.setState({ quantum })
            }}
          />
          <TextFieldFormGroup
            label='Vault ID'
            placeholder='Vault ID'
            value={this.state.vaultId}
            onChange={event => {
              const vaultId = event.target.value
              this.setState({ vaultId })
              localStorage.setItem('starkDepositErc20::vaultId', vaultId)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Deposit
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
