import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      assetStandard: localStorage.getItem('starkTransfer::assetStandard') || 'ETH',
      amount: localStorage.getItem('starkTransfer::amount') || '1',
      quantum: localStorage.getItem('starkTransfer::quantum') || '1000',

      senderVaultId: localStorage.getItem('starkTransfer::senderVaultId') || '34',

      receiverVaultId: localStorage.getItem('starkTransfer::receiverVaultId') || '35',
      receiverKey: localStorage.getItem('starkTransfer::receiverKey') || '0x04887f4c82218a6d13c6a0a249413e6e53b6910de5f0e6b5d85cfef1db04553b',

      nonce: localStorage.getItem('starkTransfer::nonce') || '1',
      expirationTimestamp: localStorage.getItem('starkTransfer::expirationTimestamp') || '438953',
      condition: localStorage.getItem('starkTransfer::condition') || '',
      conditionalTransferAddress: localStorage.getItem('starkTransfer::conditionalTransferAddress') || '',
      conditionalTransferFact: localStorage.getItem('starkTransfer::conditionalTransferFact') || ''
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Transfer'}
        subheader={'starkProvider.transfer({...params})'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Asset standard'
            placeholder='Asset standard'
            value={this.state.assetStandard}
            onChange={event => {
              const assetStandard = event.target.value
              this.setState({ assetStandard })
              localStorage.setItem('starkTransfer::assetStandard', assetStandard)
            }}
          />
          <TextFieldFormGroup
            label='Amount'
            placeholder='Amount'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              this.setState({ amount })
              localStorage.setItem('starkTransfer::amount', amount)
            }}
          />
          <TextFieldFormGroup
            label='Quantum'
            placeholder='Quantum'
            value={this.state.quantum}
            onChange={event => {
              const quantum = event.target.value
              this.setState({ quantum })
              localStorage.setItem('starkTransfer::quantum', quantum)
            }}
          />
          <TextFieldFormGroup
            label='Sender Vault ID'
            placeholder='Sender vault ID'
            value={this.state.senderVaultId}
            onChange={event => {
              const senderVaultId = event.target.value
              this.setState({ senderVaultId })
              localStorage.setItem('starkTransfer::senderVaultId', senderVaultId)
            }}
          />
          <TextFieldFormGroup
            label='Receiver Vault ID'
            placeholder='Receiver vault ID'
            value={this.state.receiverVaultId}
            onChange={event => {
              const receiverVaultId = event.target.value
              this.setState({ receiverVaultId })
              localStorage.setItem('starkTransfer::receiverVaultId', receiverVaultId)
            }}
          />
          <TextFieldFormGroup
            label='Receiver key'
            placeholder='Receiver key'
            value={this.state.receiverKey}
            onChange={event => {
              const receiverKey = event.target.value
              this.setState({ receiverKey })
              localStorage.setItem('starkTransfer::receiverKey', receiverKey)
            }}
          />
          <TextFieldFormGroup
            label='Nonce'
            placeholder='Nonce'
            value={this.state.nonce}
            onChange={event => {
              const nonce = event.target.value
              this.setState({ nonce })
              localStorage.setItem('starkTransfer::nonce', nonce)
            }}
          />
          <TextFieldFormGroup
            label='Expiration Timestamp'
            placeholder='Expiration Timestamp'
            value={this.state.expirationTimestamp}
            onChange={event => {
              const expirationTimestamp = event.target.value
              this.setState({ expirationTimestamp })
              localStorage.setItem('starkTransfer::expirationTimestamp', expirationTimestamp)
            }}
          />
          <TextFieldFormGroup
            label='Condition'
            placeholder='Condition'
            value={this.state.condition}
            onChange={event => {
              const condition = event.target.value
              this.setState({ condition })
              localStorage.setItem('starkTransfer::condition', condition)
            }}
          />
          <TextFieldFormGroup
            label='Conditional Transfer Address'
            placeholder='Conditional Transfer Address'
            value={this.state.conditionalTransferAddress}
            onChange={event => {
              const conditionalTransferAddress = event.target.value
              this.setState({ conditionalTransferAddress })
              localStorage.setItem('starkTransfer::conditionalTransferAddress', conditionalTransferAddress)
            }}
          />
          <TextFieldFormGroup
            label='Conditional Transfer Fact'
            placeholder='Conditional Transfer Fact'
            value={this.state.conditionalTransferFact}
            onChange={event => {
              const conditionalTransferFact = event.target.value
              this.setState({ conditionalTransferFact })
              localStorage.setItem('starkTransfer::conditionalTransferFact', conditionalTransferFact)
            }}
          />
          <TextValueFormGroup
            label='Signature'
            whitespace='break-spaces'
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Create transfer signature
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {
      assetStandard,
      amount,
      quantum,
      senderVaultId,
      receiverVaultId,
      receiverKey,
      nonce,
      expirationTimestamp,
      condition,
      conditionalTransferAddress,
      conditionalTransferFact
    } = this.state

    const input = {
      asset: {
        type: assetStandard,
        data: {
          quantum
        }
      },
      from: {
        vaultId: senderVaultId
      },
      to: {
        starkKey: receiverKey,
        vaultId: receiverVaultId
      },
      amount,
      nonce,
      expirationTimestamp,
      condition,
      conditionalTransferAddress,
      conditionalTransferFact
    }

    this.props.onSubmit(input)
  }
}
