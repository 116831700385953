import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      amount: localStorage.getItem('starkDepositEth::amount') || '1000000000000000',
      quantum: localStorage.getItem('starkDepositEth::quantum') || '10',
      vaultId: localStorage.getItem('starkDepositEth::vaultId') || '15'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Deposit ETH'}
        subheader={'starkProvider.depositEth(vaultId, amount, quantum)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Amount (wei)'
            placeholder='Enter amount in wei'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              localStorage.setItem('starkDepositEth::amount', amount)
              this.setState({ amount })
            }}
          />
          <TextFieldFormGroup
            label='Quantum'
            placeholder='Enter quantum'
            value={this.state.quantum}
            onChange={event => {
              const quantum = event.target.value
              localStorage.setItem('starkDepositEth::quantum', quantum)
              this.setState({ quantum })
            }}
          />
          <TextFieldFormGroup
            label='Vault ID'
            placeholder='Vault ID'
            value={this.state.vaultId}
            onChange={event => {
              const vaultId = event.target.value
              this.setState({ vaultId })
              localStorage.setItem('starkDepositEth::vaultId', vaultId)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Deposit
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
