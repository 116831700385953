import React from 'react'
import TextValueFormGroup from 'src/components/TextValueFormGroup'

export default class Component extends React.Component {
  render () {
    if (!this.props.value) {
      return null
    }

    return (
      <TextValueFormGroup
        label={this.props.label}
        value={this.props.value}
        variant='error' />
    )
  }
}
