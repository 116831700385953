import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      vaultId: localStorage.getItem('starkEscape::vaultId') || '15',
      assetType: localStorage.getItem('starkEscape::assetType') || '0x4b744eda38322858d42ba43046badd5bd91e94844c0b7c47a4975d8b5b77b5',
      quantizedAmount: localStorage.getItem('starkEscape::quantizedAmount') || '8233298795789816600'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Escape'}
        subheader={'starkProvider.escape(vaultId, assetType, quantizedAmount )'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Vault ID'
            placeholder='Vault ID'
            value={this.state.vaultId}
            onChange={event => {
              const vaultId = event.target.value
              this.setState({ vaultId })
              localStorage.setItem('starkEscape::vaultId', vaultId)
            }}
          />
          <TextFieldFormGroup
            label='Asset type'
            placeholder='Asset type'
            value={this.state.assetType}
            onChange={event => {
              const assetType = event.target.value
              this.setState({ assetType })
              localStorage.setItem('starkEscape::assetType', assetType)
            }}
          />
          <TextFieldFormGroup
            label='Quantized Amount'
            placeholder='Quantized Amount'
            value={this.state.quantizedAmount}
            onChange={event => {
              const quantizedAmount = event.target.value
              this.setState({ quantizedAmount })
              localStorage.setItem('starkEscape::quantizedAmount', quantizedAmount)
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Escape
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    let { vaultId, token, quantizedAmount } = this.state

    try {
      token = JSON.parse(token)
    } catch (err) {
      // noop
    }

    this.props.onSubmit({
      vaultId,
      token,
      quantizedAmount
    })
  }
}
