import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import snakeCase from '@authereum/utils/core/snakeCase'
import slugify from '@authereum/utils/core/slugify'
import storage from 'src/storage'

const styles = {
  title: {
    '&> a': {
      opacity: 0,
      color: '#0000002e'
    },
    '&:hover': {
      '&> a': {
        opacity: 1,
        display: 'inline-block'
      }
    }
  },
  accordionSummaryContent: {
    margin: 0,
    padding: 0,
    '&.expanded': {
      margin: 0,
      padding: 0
    }
  },
  accordionSummaryExpandIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    margin: 0,
    padding: 0
  }
}

function CardComponent (props) {
  const key = `cardExpanded::${snakeCase(props.title)}`
  let cachedValue = storage.getItem(key)

  const [expanded, setExpanded] = useState(() => {
    try {
      cachedValue = JSON.parse(cachedValue)
      if (cachedValue !== null) {
        return cachedValue
      }
    } catch (err) {
      // noop
    }

    return true
  })

  const handleChange = (event, value) => {
    setExpanded(value)
    storage.setItem(key, value)
  }

  const id = slugify(props.title)

  return (
    <Grid
      style={{
        width: '450px',
        margin: '0 auto 2em auto'
      }}
      item>
      <Card>
        <Accordion
          expanded={expanded}
          onChange={handleChange}
        >
          <AccordionSummary
            classes={{
              root: props.classes.accordionSummaryContent,
              content: props.classes.accordionSummaryContent,
              expanded: 'expanded',
              expandIcon: props.classes.accordionSummaryExpandIcon
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls='expansion-panel-content'
          >
            <CardHeader
              style={{ width: '100%' }}
              title={
                <span
                  className={props.classes.title}
                  id={id}>
                  {props.title}
                  <a
                    href={`#${id}`}>{'#'}</a>
                </span>
              }
              subheader={props.subheader} />
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <CardContent style={{ width: '100%' }}>
              {props.children}
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(CardComponent)
