import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('zkSyncWithdraw::token') || '',
      amount: localStorage.getItem('zkSyncWithdraw::amount') || '0.1'
    }
  }

  render () {
    return (
      <Card
        title={'ZkSync: Withdraw'}
        subheader={'zkSyncProvider.withdraw({amount, token})'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Amount'
            placeholder='Enter amount'
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              localStorage.setItem('zkSyncWithdraw::amount', amount)
              this.setState({ amount })
            }}
          />
          <TextFieldFormGroup
            label='Token (leave blank for ETH)'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('zkSyncWithdraw::token', token)
              this.setState({ token })
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Withdraw
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
