import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

export default class Component extends React.Component {
  render () {
    return (
      <Grid
        style={{
          padding: '1em',
          justifyContent: 'center',
          display: 'flex',
          background: '#fff',
          width: '100%',
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: 10,
          borderBottom: '1px solid #ddd'
        }}
        item>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <div style={{ marginRight: '1em' }}>
            <CircularProgress size={40} />
          </div>
          <Typography
            variant='body1'
            style={{
              fontSize: '1em'
            }}>
              Initializing...
          </Typography>
        </div>
      </Grid>
    )
  }
}
