import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      tokenId: localStorage.getItem('starkTransferErc721::tokenId') || '1234',
      assetContractAddress: localStorage.getItem('starkTransferErc721::token') || '0x6B5E013ba22F08ED46d33Fa6d483Fd60e001262e',
      nonce: localStorage.getItem('starkTransferErc721::nonce') || '1',
      senderVaultId: localStorage.getItem('starkTransferErc721::senderVaultId') || '34',
      assetType: localStorage.getItem('starkTransferErc721::assetType') || '0x4b744eda38322858d42ba43046badd5bd91e94844c0b7c47a4975d8b5b77b5',
      receiverVaultId: localStorage.getItem('starkTransferErc721::receiverVaultId') || '35',
      receiverKey: localStorage.getItem('starkTransferErc721::receiverKey') || '0x04887f4c82218a6d13c6a0a249413e6e53b6910de5f0e6b5d85cfef1db04553b',
      expirationTimestamp: localStorage.getItem('starkTransferErc721::expirationTimestamp') || '438953',
      condition: localStorage.getItem('starkTransferErc721::condition') || ''
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Transfer ERC721'}
        subheader={'starkProvider.transferErc721(params)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token ID'
            placeholder='Token ID'
            value={this.state.tokenId}
            onChange={event => {
              const tokenId = event.target.value
              this.setState({ tokenId })
              localStorage.setItem('starkTransferErc721::tokenId', tokenId)
            }}
          />
          <TextFieldFormGroup
            label='Token'
            placeholder='Token'
            value={this.state.assetContractAddress}
            onChange={event => {
              const assetContractAddress = event.target.value
              this.setState({ assetContractAddress })
              localStorage.setItem('starkTransferErc721::token', assetContractAddress)
            }}
          />
          <TextFieldFormGroup
            label='Sender Vault ID'
            placeholder='Sender vault ID'
            value={this.state.senderVaultId}
            onChange={event => {
              const senderVaultId = event.target.value
              this.setState({ senderVaultId })
              localStorage.setItem('starkTransferErc721::senderVaultId', senderVaultId)
            }}
          />
          <TextFieldFormGroup
            label='Receiver Vault ID'
            placeholder='Receiver vault ID'
            value={this.state.receiverVaultId}
            onChange={event => {
              const receiverVaultId = event.target.value
              this.setState({ receiverVaultId })
              localStorage.setItem('starkTransferErc721::receiverVaultId', receiverVaultId)
            }}
          />
          <TextFieldFormGroup
            label='Receiver key'
            placeholder='Receiver key'
            value={this.state.receiverKey}
            onChange={event => {
              const receiverKey = event.target.value
              this.setState({ receiverKey })
              localStorage.setItem('starkTransferErc721::receiverKey', receiverKey)
            }}
          />
          <TextFieldFormGroup
            label='Nonce'
            placeholder='Nonce'
            value={this.state.nonce}
            onChange={event => {
              const nonce = event.target.value
              this.setState({ nonce })
              localStorage.setItem('starkTransferErc721::nonce', nonce)
            }}
          />
          <TextFieldFormGroup
            label='Expiration Timestamp'
            placeholder='Expiration Timestamp'
            value={this.state.expirationTimestamp}
            onChange={event => {
              const expirationTimestamp = event.target.value
              this.setState({ expirationTimestamp })
              localStorage.setItem('starkTransferErc721::expirationTimestamp', expirationTimestamp)
            }}
          />
          <TextFieldFormGroup
            label='Condition'
            placeholder='Condition'
            value={this.state.condition}
            onChange={event => {
              const condition = event.target.value
              this.setState({ condition })
              localStorage.setItem('starkTransferErc721::condition', condition)
            }}
          />
          <TextValueFormGroup
            label='Signature'
            whitespace='break-spaces'
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Create transfer signature
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
