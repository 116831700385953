import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      assetStandard: localStorage.getItem('starkGetAssetType::assetStandard') || 'ERC20',
      assetContractAddress: localStorage.getItem('starkGetAssetType::assetContractAddress') || '0x0d9c8723b343a8368bebe0b5e89273ff8d712e3c',
      quantum: localStorage.getItem('starkGetAssetType::quantum') || '1000'
    }
  }

  render () {
    return (
      <Card
        title={'StarkWare: Get Asset Type'}
        subheader={'starkProvider.getAssetType({assetStandard, assetContractAddress, quantum})'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Asset standard'
            placeholder='Enter asset standard'
            value={this.state.assetStandard}
            onChange={event => {
              const assetStandard = event.target.value
              localStorage.setItem('starkGetAssetType::assetStandard', assetStandard)
              this.setState({ assetStandard })
            }}
          />
          <TextFieldFormGroup
            label='Asset contract address'
            placeholder='Enter asset contract address'
            value={this.state.assetContractAddress}
            onChange={event => {
              const assetContractAddress = event.target.value
              localStorage.setItem('starkGetAssetType::assetContractAddress', assetContractAddress)
              this.setState({ assetContractAddress })
            }}
          />
          <TextFieldFormGroup
            label='Quantum'
            placeholder='Quantum'
            value={this.state.quantum}
            onChange={event => {
              const quantum = event.target.value
              this.setState({ quantum })
              localStorage.setItem('starkGetAssetType::quantum', quantum)
            }}
          />
          <TextValueFormGroup
            label='Asset type'
            value={this.props.assetType}
          />
          <ButtonFormGroup>
            Get asset type
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.onSubmit(this.state)
  }
}
