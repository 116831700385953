import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: localStorage.getItem('nftTransferFrom::token') || '0x4f41d10f7e67fd16bde916b4a6dc3dd101c57394',
      from: localStorage.getItem('nftTransferFrom::from') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      to: localStorage.getItem('nftTransferFrom::to') || '0x96E782B8CCC76F239043A3aE5Ae17cbB67399aA2',
      tokenId: localStorage.getItem('nftTransferFrom::tokenId') || '8117'
    }
  }

  render () {
    return (
      <Card
        title={'ERC721: Transfer From'}
        subheader={'token.transferFrom(from, to, tokenId)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='Token'
            placeholder='Enter token address'
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('nftTransferFrom::token', token)
              this.setState({ token })
            }}
          />
          <TextFieldFormGroup
            label='From'
            placeholder='Enter from address'
            value={this.state.from}
            onChange={event => {
              const from = event.target.value
              localStorage.setItem('nftTransferFrom::from', from)
              this.setState({ from })
            }}
          />
          <TextFieldFormGroup
            label='To'
            placeholder='Enter to address'
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('nftTransferFrom::to', to)
              this.setState({ to })
            }}
          />
          <TextFieldFormGroup
            label='Token ID'
            placeholder='Enter token ID'
            value={this.state.tokenId}
            onChange={event => {
              const tokenId = event.target.value
              localStorage.setItem('nftTransferFrom::tokenId', tokenId)
              this.setState({ tokenId })
            }}
          />
          <TextValueFormGroup
            label='Transaction hash'
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Transfer
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
