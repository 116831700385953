import React from 'react'
import Card from 'src/components/Card'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import TextValueFormGroup from 'src/components/TextValueFormGroup'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import localStorage from 'src/storage'

export default class Component extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      data: localStorage.getItem('signTypedData::data') || JSON.stringify([
        {
          type: 'string',
          name: 'Name',
          value: 'Alice'
        },
        {
          type: 'uint32',
          name: 'Amount',
          value: '5000'
        }
      ], null, 2)
    }
  }

  render () {
    return (
      <Card
        title={'Sign Typed Data'}
        subheader={`provider.sendAsync({method: 'eth_signTypedData', params})`}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label='JSON'
            multiline
            rowsMax={10}
            placeholder='Enter JSON data'
            value={this.state.data}
            onChange={event => {
              const data = event.target.value
              this.setState({ data })
              localStorage.setItem('signTypedData::data', data)
            }}
          />
          <TextValueFormGroup
            label='Hash'
            value={this.props.hash}
          />
          <TextValueFormGroup
            label='Signature'
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Sign typed data
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
