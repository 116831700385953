import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

export default class Component extends React.Component {
  render () {
    return (
      <FormGroup row>
        <FormControl
          fullWidth
          margin='dense'>
          <TextField
            variant='outlined'
            type='text'
            {...this.props}
          />
        </FormControl>
      </FormGroup>
    )
  }
}
